<template>


    <div class="content">
    <validation-observer ref="VForm">
    <b-form @submit.prevent="doSubmit">
        <div class="card">
            <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Pengkajian Fisioterapi</h6>
            </div>
            
            <div class="card-body p-3">
            <div class="row">
                <div class="col-md-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Tekanan Darah</label>
                            <div class="input-group">
                                <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                <b-form-input :formatter="$parent.number" placeholder="Diastole" v-model="row.appfa_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>
                            <VValidate 
                                name="Tekanan Darah Min" 
                                v-model="row.appfa_ttv_tekanan_darah_min" 
                                :rules="toValidate(mrValidation.appfa_ttv_tekanan_darah_min)"
                            />
                            <VValidate 
                                name="Tekanan Darah Max" 
                                v-model="row.appfa_ttv_tekanan_darah_max" 
                                :rules="toValidate(mrValidation.appfa_ttv_tekanan_darah_max)"
                            />
                            </div>
                        </div>

                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Nadi</label>
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <div class="input-group">
                                        <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span>
                                        </div>
                                        <div class="input-group-append">
                                            <div style="width: 140px;">
                                            <v-select placeholder="Pilih Label" v-model="row.appfa_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <VValidate 
                                    name="Nadi" 
                                    v-model="row.appfa_ttv_nadi" 
                                    :rules="toValidate(mrValidation.appfa_ttv_nadi)"
                                />
                                <VValidate 
                                    name="Label" 
                                    v-model="row.appfa_ttv_label" 
                                    :rules="toValidate(mrValidation.appfa_ttv_label)"
                                />
                            </div>
                        </div>
                        
                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Gula Darah</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_gula_darah"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <VValidate 
                                name="Gula Darah" 
                                v-model="row.appfa_ttv_gula_darah" 
                                :rules="toValidate(mrValidation.appfa_ttv_gula_darah)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Pernafasan</label>
                            <div class="input-group">
                                <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_pernafasan"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>
                            </div>
                            
                            <VValidate 
                                name="Pernafasan" 
                                v-model="row.appfa_ttv_pernafasan" 
                                :rules="toValidate(mrValidation.appfa_ttv_pernafasan)"
                            />
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_spo2"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <VValidate 
                                name="SPO2" 
                                v-model="row.appfa_ttv_spo2" 
                                :rules="toValidate(mrValidation.appfa_ttv_spo2)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <VValidate 
                                name="Suhu" 
                                v-model="row.appfa_ttv_suhu" 
                                :rules="toValidate(mrValidation.appfa_ttv_suhu)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input @input="hitungBMI()" :formatter="$parent.number" v-model="row.appfa_ttv_weight"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <VValidate 
                                name="Berat Badan" 
                                v-model="row.appfa_ttv_weight" 
                                :rules="toValidate(mrValidation.appfa_ttv_weight)"
                            />
                            </div>
                        </div>

                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Tinggi</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input @input="hitungBMI()" :formatter="$parent.number" v-model="row.appfa_ttv_height"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <VValidate 
                                name="Tinggi Badan" 
                                v-model="row.appfa_ttv_height" 
                                :rules="toValidate(mrValidation.appfa_ttv_height)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Lingkar Kepala</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="$parent.number" v-model="row.appfa_ttv_lingkar_kepala"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <VValidate 
                                name="Lingkar Kepala" 
                                v-model="row.appfa_ttv_lingkar_kepala" 
                                :rules="toValidate(mrValidation.appfa_ttv_lingkar_kepala)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3" v-if="row.ap_usia >= 1">
                            <div class="form-group">
                            <label>BMI</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input disabled v-model="row.appfa_ttv_bmi"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">m2</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        <div class="col-md-3" v-if="row.ap_usia <= 15">
                            <div class="form-group">
                            <label>Luas Permukaan Tubuh Anak</label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.appfa_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Kesadaran</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <v-select placeholder="Pilih Kesadaran" v-model="row.appfa_ttv_kesadaran" :options="mKesadaran" label="text" :clearable="true" :reduce="v=>v.value">
                                        <template slot="selected-option" slot-scope="option">
                                        <span v-b-tooltip.hover :title="option.text">{{ option.text }}</span>
                                        </template>
                                        <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover :title="option.text">{{ option.text }}</span>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <VValidate 
                                name="Kesadaran" 
                                v-model="row.appfa_ttv_kesadaran" 
                                :rules="toValidate(mrValidation.appfa_ttv_kesadaran)"
                            />
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
                </div>
                        
                <div class="col-12">
                    <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Status Fungsional</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row gx-5">
                        <div class="col-md-auto">
                            <div>
                            <label for="perHub">Alat Bantu<strong class="text-danger">*</strong></label>
                            <div>
                                <b-form-radio-group
                                    
                                    :options="Config.mr.yesNoOptV2"
                                    v-model="row.appfa_alat_bantu"
                                />

                                <v-select class="mt-3" v-if="row.appfa_alat_bantu == 'Y'" placeholder="Pilih Alat Bantu" v-model="row.appfa_alat_bantu_lainnya" :options="mAlatBantu" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                
                                <VValidate 
                                    v-if="row.appfa_alat_bantu == 'Y'"
                                    name="Nama Alat Bantu" 
                                    v-model="row.appfa_alat_bantu_lainnya" 
                                    :rules="toValidate(mrValidation.appfa_alat_bantu_lainnya)"
                                /> 
                            </div> 
                            <VValidate 
                                name="Alat Bantu" 
                                v-model="row.appfa_alat_bantu" 
                                :rules="toValidate(mrValidation.appfa_alat_bantu)"
                            /> 
                            </div>
                        </div>
                        <div class="col-md-auto border-left">
                            <div>
                            <label for="perHub">ADL (Aktifitas Sehari-hari)<strong class="text-danger">*</strong></label>
                            <div>                    
                                <b-form-radio-group
                                    
                                    :options="Config.mr.adlStatus"
                                    v-model="row.appfa_adl"
                                />
                            </div>
                            </div>
                            
                            <VValidate 
                                name="Aktifitas Sehari-hari" 
                                v-model="row.appfa_adl" 
                                :rules="toValidate(mrValidation.appfa_adl)"
                            /> 
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pengkajian Nyeri</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                            <div class="form-group">
                                <label>Keluhan Utama <small class="txt_mandatory">*</small></label>

                                <b-form-textarea v-model="row.appfa_keluhan_utama" :formatter="$parent.normalText"
                                    name="perRiwayat" id="perRiwayat" rows="4" class="form-control"
                                    placeholder="Keluhan Utama dari pasien">
                                </b-form-textarea>

                                <VValidate name="Keluhan Utama" v-model="row.appfa_keluhan_utama"
                                    :rules="toValidate(mrValidation.appfa_keluhan_utama)" />
                            </div>
                            </div>
                            <div class="col-md-12">
                            <div class="wrap_anatomy_info">
                                <label for="perKeluhan">Lokasi Nyeri<strong class="text-danger">*</strong></label>
                                
                                <div class="position-relative" id="anatomiNyeri" @click.stop.prevent>
                                <div class="row">
                                    <div class="col-md-6">
                                    <div class="body_wrapper">
                                        <img :src="$parent.assetLocal('img/front-body.png')" class="img-fluid" alt="">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="body_wrapper">
                                        <img :src="$parent.assetLocal('img/back-body.png')" class="img-fluid" alt="">
                                    </div>
                                    </div>
                                </div>
                                <div class="anatomy-info" v-for="(v,k) in row.appfa_lokasi_nyeri||[]" :key="k"
                                    :style="`top:${v.y}%;left:${v.x}%`">
                                    <a href="javascript:;" @click="openAnat(k)" class="ai-point" v-b-tooltip.hover :html="true"
                                    :title="v.value"></a>
                                    <div :class="v.isOpen?'anatomy-info-input d-block':'anatomy-info-input'" @click.stop.prevent>
                                    <textarea v-model="v.value" name="anatomyInput" id="anatomyInput" rows="2"
                                        class="form-control form-control-sm"></textarea>
                                    <div class="mt-2 text-right">
            
                                        <a href="javascript:;" class="btn btn-sm btn-danger" @click.prevent.stop="removeAnat(k)">Hapus</a>
                                        <a href="javascript:;" @click.prevent.stop="closeAnat(k)" class="btn btn-sm btn-success">Simpan</a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group mb-3 mt-3">
                                <label for="perKeluhan">Apakah nyerinya berpindah dari satu ke tempat lain<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                <b-form-radio-group
                                    :options="Config.mr.configNilaiNyeri"
                                    v-model="row['appfa_nilai_nyeri']"
                                />
                                <VValidate 
                                    name="Rasa Nyeri Pindah" 
                                    v-model="row.appfa_nilai_nyeri" 
                                    :rules="toValidate(mrValidation.appfa_nilai_nyeri)"
                                />
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label for="perKeluhan">Berapa Lama nyeri dirasakan<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                <b-form-radio-group
                                    :options="Config.mr.configLamaNyeri"
                                    v-model="row['appfa_lama_nyeri']"
                                />
                                <VValidate 
                                    name="Lama Nyeri" 
                                    v-model="row.appfa_lama_nyeri" 
                                    :rules="toValidate(mrValidation.appfa_lama_nyeri)"
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">

                        <div class="form-group mb-3 mt-3">
                            <label for="perKeluhan">Rasa Nyeri<strong class="text-danger">*</strong></label>
                            <div class="row">
                                <b-form-radio-group
                                    class="col-md-5 custom-block"
                                    :options="Config.mr.statusNyeri"
                                    v-model="row['appfa_rasa_nyeri']"
                                />
                                <VValidate 
                                    name="Rasa Nyeri" 
                                    v-model="row.appfa_rasa_nyeri" 
                                    :rules="toValidate(mrValidation.appfa_rasa_nyeri)"
                                />
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="perKeluhan">Apakah yang membuat nyeri berkurang atau bertambah?<strong
                                class="text-danger">*</strong></label>
                            <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                    <th colspan="2" class="table-light">Kompres:</th>
                                    </tr>
                                    <tr>
                                    <td v-for="v,k in Config.mr.kompres||[]" :key="k+'kompress'" >
                                        <div class="form-check mb-2  form-check-inline align-middle">
                                            <b-form-radio v-model="row.appfa_kompres" name="kompress" :value="v.value">{{v.text}}</b-form-radio>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <th colspan="2" class="table-light">Aktifitas:</th>
                                    </tr>
                                    <tr>
                                    <td v-for="v,k in Config.mr.aktifitas||[]" :key="k+'aktifitas'" >
                                        <div class="form-check mb-2  form-check-inline align-middle">
                                            <b-form-radio v-model="row.appfa_aktifitas" name="aktifitas" :value="v.value">{{v.text}}</b-form-radio>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <VValidate 
                                    name="Kompres" 
                                    v-model="row.appfa_kompres" 
                                    :rules="toValidate(mrValidation.appfa_kompres)"
                                />
                                <VValidate 
                                    name="Aktivitas" 
                                    v-model="row.appfa_aktifitas" 
                                    :rules="toValidate(mrValidation.appfa_aktifitas)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-6 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Assessment Fisioterapi</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                            <tr>
                                <td width="30">
                                <div class="label_code">
                                    <h2>S</h2>
                                </div>
                                </td>
                                <td>
                                    <b-textarea v-model="row.appfa_subjektif" rows="2" cols="3" class="form-control" placeholder=""
                                        spellcheck="false"></b-textarea>
                                    <VValidate 
                                        name="Subjektif" 
                                        v-model="row.appfa_subjektif" 
                                        :rules="toValidate(mrValidation.appfa_subjektif)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td width="30">
                                <div class="label_code">
                                    <h2>O</h2>
                                </div>
                                </td>
                                <td>
                                    <b-textarea v-model="row.appfa_objektif" rows="2" cols="3" class="form-control" placeholder=""
                                        spellcheck="false"></b-textarea>
                                    <VValidate 
                                        name="Objektif" 
                                        v-model="row.appfa_objektif" 
                                        :rules="toValidate(mrValidation.appfa_objektif)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td width="30">
                                <div class="label_code">
                                    <h2>A</h2>
                                </div>
                                </td>
                                <td>
                                    <b-textarea v-model="row.appfa_asesmen" rows="2" cols="3" class="form-control" placeholder=""
                                        spellcheck="false"></b-textarea>
                                    <VValidate 
                                        name="Asesmen" 
                                        v-model="row.appfa_asesmen" 
                                        :rules="toValidate(mrValidation.appfa_asesmen)"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td width="30">
                                <div class="label_code">
                                    <h2>P</h2>
                                </div>
                                </td>
                                <td>
                                    <b-textarea v-model="row.appfa_plan" rows="2" cols="3" class="form-control" placeholder=""
                                        spellcheck="false"></b-textarea>
                                    <VValidate 
                                        name="Plan" 
                                        v-model="row.appfa_plan" 
                                        :rules="toValidate(mrValidation.appfa_plan)"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-6 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <b-textarea v-model="row.appfa_rencana_tindakan" rows="7" cols="3" class="form-control" placeholder=""
                            spellcheck="false"></b-textarea>
                        <VValidate 
                            name="Rencana Tindakan" 
                            v-model="row.appfa_rencana_tindakan" 
                            :rules="toValidate(mrValidation.appfa_rencana_tindakan)"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <div class="card border shadow-0 mt-3" v-if="row.isEdit">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                </div>
                <div class="card-body">
                    <div class="form-group">
                    <label for="addInfoTindakan">Keterangan Perubahan</label>
                    <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                    </b-textarea>

                        
                    <VValidate 
                        :name="`Keterangan Perubahan`" 
                        v-model="row.arm_notes" 
                        :rules="{required: 1, min: 2, max:512}"
                    />
                    </div>
                </div>
            </div>

            <div class="card-footer">
            <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
            </div>
        </div>
    </b-form>
    </validation-observer> 
    </div>
</template>



<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

// import GlobalVue from '@/libs/Global.vue'

export default{
    // extends: GlobalVue,
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        mKesadaran: Array,
        mAlatBantu: Array,
    },
    data(){
        return {
        }
    },
    mounted() {
      document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.setPosisionNyeri()
            this.row.arm_notes = null
        },1500)
    },
    methods: {
        hitungBMI(){
            if(this.row.appfa_ttv_weight && this.row.appfa_ttv_height){
                this.row.appfa_ttv_bmi = this.row.appfa_ttv_weight / this.row.appfa_ttv_height
                this.row.appfa_ttv_bmi = this.row.appfa_ttv_bmi.toFixed(2) 
            }
        },
        back(){
            this.$router.back()
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save-fisio-awal'
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        toValidate(val){
            return {...val}
        },
        removeAnat(k){
            this.row.appfa_lokasi_nyeri.splice(k,1)
        },
        closeAnat(k){
            this.row.appfa_lokasi_nyeri[k]['isOpen'] = false
            this.row.appfa_lokasi_nyeri[k]['y'] += (42 / $('#anatomiNyeri').height())*100
        },
        openAnat(k){
            this.row.appfa_lokasi_nyeri[k]['y'] -= (42 / $('#anatomiNyeri').height())*100
            this.row.appfa_lokasi_nyeri[k]['isOpen'] = true
        },
        setPosisionNyeri() {
            let posX = false
            let posY = false
            let self = this

            $("body").mousemove(function (e) {
                if (!$("body").hasClass("active")) $("body").addClass("active")
                if($("#anatomiNyeri").length){
                  posX = parseInt(e.pageX - ($("#anatomiNyeri").offset().left + 8))
                  posY = parseInt(e.pageY - ($("#anatomiNyeri").offset().top + 52))
                }
            })

            $('#anatomiNyeri').on('click', function (e) {
                e.stopPropagation()
                let index = self.row.appfa_lokasi_nyeri.findIndex(x => x.isOpen == true)
                if(index !== -1){
                  self.row.appfa_lokasi_nyeri.splice(index,1)
                }else{
                  self.row.appfa_lokasi_nyeri.push({
                      x: (posX / $(this).width())*100,
                      y: (posY / $(this).height())*100,
                      value: '',
                      isOpen: true,
                  })
                }
            })
        },

        doSubmit(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   

            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data-fisio-awal'
                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.$parent.user.levelId == 1){
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uFisio : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{})
                                        }   
                                        
                                        let dataSocket = {
                                          to : this.$parent.uFisio,
                                          from : this.$parent.uFisio,
                                          no_antrian: this.rowReg.ar_no_antrian_ppa_fisio
                                        }
                                        this.$socket.emit('done_penunjang', dataSocket)
                                        
                                        let dataSocketAll = {
                                          to : 'ALLPJ',
                                          from : this.$parent.uFisio,
                                          no_antrian: this.rowReg.ar_no_antrian_ppa_fisio
                                        }
                                        this.$socket.emit('done_penunjang', dataSocketAll)

                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}

</script>