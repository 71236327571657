<template>
    <div>
        <div class="card border shadow-0">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
            </div>
            <div class="card-header pt-0">
                <ul class="nav nav-tabs nav-tabs-bottom mb-0">

                    <li class="nav-item" v-for="(v,k) in isParent.diagnosa" :key="k">
                        <a href="javascript:;" @click="changeTab(k+1)" data-toggle="tab" :data-target="'#diagTab'+(k+1)"
                            :class="isActiveTab == (k+1) ? 'nav-link active' : 'nav-link'">{{v.text||"Diagnosa baru"}}
                            <i @click="removeDiagnosa(v.aaksd_id,k)" class="icon-cross ml-2"></i>
                        </a>
                    </li>

                    <li class="nav-item"><a @click="addNewDiagnosa" href="javascript:;" class="nav-link">
                            <i class="icon-plus2 mr-2"></i> Tambah Diagnosa
                        </a></li>
                </ul>
            </div>

            <div class="card-body">
                <div class="tab-content">
                    <div v-for="(v,k) in isParent.diagnosa" :key="k+'a'"
                        :class="isActiveTab == (k+1) ? 'tab-pane fade show active':'tab-pane fade'"
                        :id="'#diagTab'+(k+1)">
                        <div class="row g-3">
                            <div class="col-md-8">
                                <label for="diagnosa">Pilih Diagnosa</label>
                                <v-select placeholder="Pilih Diagnosa" class="diagnosa-section select-paging" v-model="isParent.diagnosa[k].aaksd_diagnosa_id"
                                    @input="selectPenyebab($event,k)" @search="filterSDKI($event,k)"
                                    :options="getMasterSDKI(isParent.mSDKI,isParent.diagnosa,k)" label="text"
                                    :clearable="true" :reduce="v=>v.value">
                                    <template slot="selected-option">
                                        <span>{{ v.text || "-" }}</span>
                                    </template>

                                    <template #list-footer>
                                      <li class="list-paging" v-if="ceilData(v.totalRows/10)">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <b-button @click="changePageSDKI(v.currentPage,k,'min','diagnosa')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                          <small>Halaman {{v.currentPage}} dari {{ceilData(v.totalRows/10)}}</small>
                                          <b-button @click="changePageSDKI(v.currentPage,k,'plus','diagnosa')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                      </li>
                                    </template>
                                </v-select>

                                <VValidate :name="`Diagnosa #${k+1}`" v-model="isParent.diagnosa[k].aaksd_diagnosa_id"
                                    :rules="{required: 1}" />
                            </div>
                            <div class="col-6" v-if="isParent.diagnosa[k].aaksd_diagnosa_id">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Penyebab</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <b-form-checkbox-group
                                            v-if="(isParent.diagnosa[k].aaksd_config_sdki.msd_penyebab||[]).length"
                                            :id="'penyebab'+k" v-model="isParent.diagnosa[k].aaksd_penyebab"
                                            :options="isParent.diagnosa[k].aaksd_config_sdki.msd_penyebab"
                                            :name="'penyebab'+k" value-field="name" text-field="name" stacked>
                                        </b-form-checkbox-group>
                                        <span v-else> (Tidak Ada) </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" v-if="isParent.diagnosa[k].aaksd_diagnosa_id">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row gx-4">
                                            <div class="col-md-6">
                                                <div class="signsCheck">
                                                    <b-form-checkbox-group
                                                        v-if="(isParent.diagnosa[k].aaksd_config_sdki.msd_faktor_resiko||[]).length"
                                                        v-model="isParent.diagnosa[k].aaksd_faktor_resiko"
                                                        :options="isParent.diagnosa[k].aaksd_config_sdki.msd_faktor_resiko"
                                                        :id="'faktor_resiko'+k" :name="'faktor_resiko'+k" value-field="name"
                                                        text-field="name" stacked></b-form-checkbox-group>
                                                    <span v-else> (Tidak Ada) </span>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="isParent.diagnosa[k].aaksd_diagnosa_id">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row gx-4">
                                            <div class="col-md-6">
                                                <div class="signsCheck">
                                                    <h6 class="font-weight-semibold">Subjective</h6>
                                                    <b-form-checkbox-group
                                                        v-if="(isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_mayor_subjektif||[]).length"
                                                        v-model="isParent.diagnosa[k].aaksd_gejala_mayor_subjektif"
                                                        :options="isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_mayor_subjektif"
                                                        :id="'mayor_sub'+k" :name="'mayor_sub'+k" value-field="name"
                                                        text-field="name" stacked></b-form-checkbox-group>
                                                    <span v-else> (Tidak Ada) </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="signsCheck">
                                                    <h6 class="font-weight-semibold">Objective</h6>
                                                    <b-form-checkbox-group
                                                        v-if="(isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_mayor_objektif||[]).length"
                                                        v-model="isParent.diagnosa[k].aaksd_gejala_mayor_objektif"
                                                        :options="isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_mayor_objektif"
                                                        :id="'mayor_obj'+k" :name="'mayor_obj'+k" value-field="name"
                                                        text-field="name" stacked></b-form-checkbox-group>
                                                    <span v-else> (Tidak Ada) </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="isParent.diagnosa[k].aaksd_diagnosa_id">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row gx-4">
                                            <div class="col-md-6">
                                                <div class="signsCheck">
                                                    <h6 class="font-weight-semibold">Subjective</h6>
                                                    <b-form-checkbox-group
                                                        v-if="(isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_minor_subjektif||[]).length"
                                                        v-model="isParent.diagnosa[k].aaksd_gejala_minor_subjektif"
                                                        :options="isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_minor_subjektif"
                                                        :id="'minor_sbj'+k" :name="'minor_sbj'+k" value-field="name"
                                                        text-field="name" stacked></b-form-checkbox-group>
                                                    <span v-else> (Tidak Ada) </span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="signsCheck">
                                                    <h6 class="font-weight-semibold">Objective</h6>
                                                    <b-form-checkbox-group
                                                        v-if="(isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_minor_objektif||[]).length"
                                                        v-model="isParent.diagnosa[k].aaksd_gejala_minor_objektif"
                                                        :options="isParent.diagnosa[k].aaksd_config_sdki.msd_gejala_minor_objektif"
                                                        :id="'minor_obj'+k" :name="'minor_obj'+k" value-field="name"
                                                        text-field="name" stacked></b-form-checkbox-group>
                                                    <span v-else> (Tidak Ada) </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Luaran Keperawatan</h6>
                                    </div>
                                    <div class="card-header">
                                        <ul class="nav nav-tabs nav-tabs-bottom mb-0">

                                            <li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
                                                <a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab"
                                                    :data-target="'#luarTab'+(k1+1)"
                                                    :class="v.aaksd_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
                                                    <!--
                                                    {{v.aaksd_last_tab_slki}} {{(k1+1)}}
                                                    -->

                                                    {{ v1.text || "Luaran Baru"}}
                                                    <i @click="removeLuaran(v1.aaksl_id,k,k1)"
                                                        class="icon-cross ml-2"></i> </a>
                                            </li>

                                            <li class="nav-item"><a href="javascript:;"
                                                @click="addLuaran(v.aaksd_diagnosa_id, v.aaksd_arm_id, v.aaksd_id, k)"
                                                class="nav-link">
                                                <i class="icon-plus2 mr-2"></i> Tambah Luaran
                                            </a></li>

                                        </ul>
                                    </div>

                                    <div class="tab-content">
                                        <div v-for="(v1,k1) in v.slki" :key="k1+'lu'"
                                            :class="v.aaksd_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'"
                                            :id="'#luarTab'+(k1+1)">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div>
                                                            <label for="diagnosa">Pilih Luaran</label>
                                                            <v-select placeholder="Pilih Luaran"
                                                                class="diagnosa-section select-paging"
                                                                v-model="isParent.diagnosa[k]['slki'][k1]['aaksl_diagnosa_luaran_id']"
                                                                @search="filterSLKI($event,k,k1)"
                                                                :options="getMasterSLKI(isParent.diagnosa[k]['mSlki'],isParent.diagnosa[k]['slki'],k1)"
                                                                @input="selectedKriteriaHasil($event,k,k1)" label="text"
                                                                :clearable="true" :reduce="v=>v.value">
                                                            
                                                                <template slot="selected-option">
                                                                    <span v-b-tooltip.hover.right placement='right' :title="v1.text">{{ v1.text }}</span>
                                                                </template>
                                                                <template slot="option" slot-scope="option">
                                                                    <span v-b-tooltip.hover.right placement='right' :title="option.text">{{ option.text }}</span>
                                                                </template>

                                                                <template #list-footer>
                                                                    <li class="list-paging" v-if="ceilData(v1.totalRows/10)">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <b-button @click="changePageSLKI(v1.currentPage,k,k1,'min','diagnosa','slki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                                                        <small>Halaman {{v1.currentPage}} dari {{ceilData(v1.totalRows/10)}}</small>
                                                                        <b-button @click="changePageSLKI(v1.currentPage,k,k1,'plus','diagnosa','slki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                                                    </div>
                                                                    </li>
                                                                </template>
                                                            </v-select>

                                                        </div>
                                                        <VValidate :name="`Luaran Keperawatan #${k+1} #${k1+1}`"
                                                            v-model="isParent.diagnosa[k]['slki'][k1]['aaksl_diagnosa_luaran_id']"
                                                            :rules="{required: 1}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <table class="table table-bordered table-striped table-hover"
                                                v-if="isParent.diagnosa[k]['slki'][k1]['aaksl_diagnosa_luaran_id']">
                                                <thead>
                                                    <tr>
                                                        <th class="table-info" colspan="99">Kriteria Hasil</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Kriteria</th>
                                                        <th colspan="5">Skor</th>
                                                        <th width="64">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(v11, k11) in (v1.aaksl_kriteria_hasil||[])"
                                                        :key="k11+'u'">
                                                        <td width="400">
                                                            <v-select placeholder="Pilih Kriteria"
                                                                v-model="isParent.diagnosa[k]['slki'][k1].aaksl_kriteria_hasil[k11]['name']"
                                                                :options="getMasterKriteriaHasil(isParent.diagnosa[k]['slki'][k1]['kriteriaHasil'],isParent.diagnosa[k]['slki'][k1].aaksl_kriteria_hasil,k11)
                                                " label="mks_criteria_hasil" :clearable="true"
                                                                :reduce="v=>v.mks_criteria_hasil"></v-select>


                                                            <VValidate
                                                                :name="`Kriteria hasil #${k+1} #${k1+1} #${k11+1}`"
                                                                v-model="isParent.diagnosa[k]['slki'][k1].aaksl_kriteria_hasil[k11]['name']"
                                                                :rules="{required: 1, min: 2, max: 512}" />
                                                        </td>
                                                        <td width="64" v-for="(vc,kc) in Config.mr.kriteriaHasil"
                                                            :key="kc">
                                                            <div>
                                                                <b-form-radio
                                                                    v-model="isParent.diagnosa[k]['slki'][k1].aaksl_kriteria_hasil[k11]['hasil']"
                                                                    :value="vc.value" :name="'scoringKrit'+k11"
                                                                    class="form-check-input-styled">{{vc.text}}
                                                                </b-form-radio>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a href="javascript:;" class="list-icons-item"
                                                                @click="deleteKriteria(k,k1,k11)" data-toggle="tooltip"
                                                                data-placement="top" title="Delete"><i
                                                                    class="icon-bin"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colspan="99" class="text-center">
                                                            <a href="javascript:;" @click="addNewKriteria(k,k1)"
                                                                class="btn btn-outline alpha-blue text-blue-800 border-blue-600">
                                                                <i class="icon-plus2 mr-2"></i> Tambah Kriteria
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="col-12">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Intervensi Keperawatan</h6>
                                    </div>
                                    <div class="card-header">
                                        <ul class="nav nav-tabs nav-tabs-bottom mb-0">

                                            <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                                                <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab"
                                                    :data-target="'#intvTab'+(k2+1)"
                                                    :class="v.aaksd_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                                                    {{ v2.text || "Intervensi Baru"}}
                                                    <i @click="removeIntervensi(v2.aaksi_id,k,k2,isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id'])"
                                                        class="icon-cross ml-2"></i> </a>
                                            </li>

                                            <li class="nav-item"><a href="javascript:;"
                                                    @click="addIntervensi(v.aaksd_diagnosa_id, v.aaksd_arm_id, v.aaksd_id, k)"
                                                    class="nav-link">
                                                    <i class="icon-plus2 mr-2"></i> Tambah Intervensi
                                                </a></li>

                                        </ul>
                                    </div>
                                    <div class="tab-content">
                                        <div v-for="(v2,k2) in v.siki" :key="k2+'sik'"
                                            :class="v.aaksd_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'"
                                            :id="'#intvTab1'+(k2+1)">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div>
                                                            <label for="diagnosa">Pilih Intervensi</label>
                                                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Intervensi"
                                                                @search="filterSIKI($event,k,k2)"
                                                                v-model="isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id']"
                                                                :options="getMasterSIKI(isParent.diagnosa[k]['mSiki'],isParent.diagnosa[k]['siki'],k2) "
                                                                @input="selectedIntervensi($event,k,k2)" label="text"
                                                                :clearable="true" :reduce="v=>v.value">
                                                                
                                                                <template slot="selected-option">
                                                                    <span v-b-tooltip.hover.right placement='right' :title="v2.text">{{ v2.text }}</span>
                                                                </template>
                                                                <template slot="option" slot-scope="option">
                                                                    <span v-b-tooltip.hover.right placement='right' :title="option.text">{{ option.text }}</span>
                                                                </template>

                                                                <template #list-footer>
                                                                    <li class="list-paging" v-if="ceilData(v2.totalRows/10)">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <b-button @click="changePageSIKI(v2.currentPage,k,k2,'min','diagnosa','siki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                                                        <small>Halaman {{v2.currentPage}} dari {{ceilData(v2.totalRows/10)}}</small>
                                                                        <b-button @click="changePageSIKI(v2.currentPage,k,k2,'plus','diagnosa','siki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                                                    </div>
                                                                    </li>
                                                                </template>
                                                            </v-select>

                                                        </div>

                                                        <VValidate :name="`Intervensi Keperawatan #${k+1} #${k2+1}`"
                                                            v-model="isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id']"
                                                            :rules="{required: 1}" />

                                                    </div>
                                                </div>
                                            </div>
                                            <table
                                                v-if="isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id']"
                                                class="table table-bordered table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="table-primary" colspan="99">Tindakan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th colspan="2" class="table-light">OBSERVASI</th>
                                                    </tr>

                                                    <tr v-for="(vo,ko) in isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_observasi']"
                                                        :key="ko+'ko'">
                                                        <td width="64">
                                                            <div class="form-check"><label class="form-check-label">
                                                                    <b-form-checkbox value="Y" :unchecked-value="null"
                                                                        v-model="isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_observasi'][ko]['value']"
                                                                        @input="addTindakanKeperawtan($event,vo.tindakan,'OBSERVASI',k,('ko'+k2+ko),isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id'])"
                                                                        :name="'tinInt'+k2+ko"
                                                                        class="form-check-input-styled" />
                                                                </label></div>
                                                        </td>
                                                        <td>{{vo.name}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    <tr>
                                                        <th colspan="2" class="table-light">TERAPEUTIK</th>
                                                    </tr>
                                                    <tr v-for="(vt,kt) in isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_terapeutik']"
                                                        :key="kt+'kt'">
                                                        <td width="64">
                                                            <div class="form-check"><label
                                                                    class="form-check-label">
                                                                    <b-form-checkbox value="Y" :unchecked-value="null"
                                                                        v-model="isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_terapeutik'][kt]['value']"
                                                                        @input="addTindakanKeperawtan($event,vt.tindakan,'TERAPEUTIK',k,('kt'+k2+kt),isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id'])"
                                                                        :name="'tinTer'+kt"
                                                                        class="form-check-input-styled" />
                                                                </label></div>
                                                        </td>
                                                        <td>{{vt.name}}</td>
                                                    </tr>
                                                </tbody>

                                                <tbody>
                                                    <tr>
                                                        <th colspan="2" class="table-light">EDUKASI</th>
                                                    </tr>
                                                    <tr v-for="(ve,ke) in isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_edukasi']"
                                                        :key="ke+'ke'">
                                                        <td width="64">
                                                            <div class="form-check"><label class="form-check-label">
                                                                    <b-form-checkbox value="Y" :unchecked-value="null"
                                                                        v-model="isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_edukasi'][ke]['value']"
                                                                        @input="addTindakanKeperawtan($event,ve.tindakan,'EDUKASI',k,('ke'+k2+ke),isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id'])"
                                                                        :name="'tinEd'+ke"
                                                                        class="form-check-input-styled" />
                                                                </label></div>
                                                        </td>
                                                        <td>{{ve.name}}</td>
                                                    </tr>
                                                </tbody>

                                                <tbody>
                                                    <tr>
                                                        <th colspan="2" class="table-light">KOLABORASI</th>
                                                    </tr>
                                                    <tr v-for="(vk,kk) in isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_kolaborasi']"
                                                        :key="kk+'kk'">
                                                        <td width="64">
                                                            <div class="form-check"><label      class="form-check-label">
                                                                    <b-form-checkbox value="Y" :unchecked-value="null"
                                                                        v-model="isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_kolaborasi'][kk]['value']"
                                                                        @input="addTindakanKeperawtan($event,vk.tindakan,'KOLABORASI',k,('kk'+k2+kk),isParent.diagnosa[k]['siki'][k2]['aaksi_diagnosa_intervensi_id'])"
                                                                        :name="'tinKol'+kk"
                                                                        class="form-check-input-styled" />
                                                                </label></div>
                                                        </td>
                                                        <td>{{vk.name}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="card border shadow-0">
                                    <div class="card-header bg-info">
                                        <h5 class="card-title font-weight-semibold">Tindakan Keperawatan</h5>
                                    </div>
                                    <div>
                                        <table class="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Diagnosa</th>
                                                    <th>Tindakan Keperawatan</th>
                                                    <th width="185">PPA</th>
                                                    <th>Waktu</th>
                                                    <th width="64">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(vti,kti) in (v.aaksd_tindakan_keperawatan||[])" :key="kti">
                                                    <td v-if="kti == 0"
                                                        :rowspan="(v.aaksd_tindakan_keperawatan||[]).length">
                                                        {{v.text||"Diagnosa baru"}}</td>
                                                    <td>{{vti.name||"-"}}</td>
                                                    <td>      
                                                        <b-form-group class="mt-3 fg-margin">
                                                            <b-form-radio-group
                                                                :options="isParent.Config.mr.isPerawatOrDokter"
                                                                v-model="vti.ppa"
                                                            />
                                                        </b-form-group>
                                                        <template>
                                                            <b-form-input v-model="vti.ppaLainnya" placeholder="Tulis Nama" />
                                                        </template>
                                                        <VValidate :name="`PPA`" v-model="vti.ppa" :rules="{required: 1}" />
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <datepicker @input="changePos(k)" input-class="form-control transparent"
                                                                placeholder="Tanggal Awal" class="my-datepicker"
                                                                calendar-class="my-datepicker_calendar"
                                                                v-model="vti.dates">
                                                            </datepicker>
                                                            <div class="input-group-append calendar-group">
                                                                <span class="input-group-text" id="basic-addon2"><i
                                                                        class="icon-calendar"></i></span>
                                                            </div>
                                                        </div>

                                                        <div class="input-group mt-2">
                                                            <vue-timepicker format="HH:mm" input-class="form-control" v-model="vti.time">
                                                            </vue-timepicker>
                                                            <div v-if="vti.time && !vti.time.HH && !vti.time.mm" id="Waktu. Kejadian"
                                                                class="label_error" style="">The Waktu. Kejadian field is required</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a v-if="isParent.rowReg.ar_status > 4" href="javascript:;" data-toggle="modal"
                                                            data-target="#FollowupTindakan"
                                                            class="btn alpha-primary border-primary text-primary-800 btn-icon rounded-round"
                                                            data-popup="tooltip" @click="openDetailFollowUp(vti,kti,k)"
                                                            v-b-tooltip.hover title="Follow Up Tindakan"><i
                                                                class="icon-file-plus"></i></a>
                                                        <span v-else> - </span>
                                                    </td>
                                                </tr>
                                                <tr v-if="!(v.aaksd_tindakan_keperawatan||[]).length">
                                                    <td colspan="99">Tidak ada Data</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="card-body" v-if="(v.aaksd_tindakan_keperawatan||[]).length">
                                        <div class="row">
                                            <div class="form-group col-6">
                                                <label for="addInfoTindakan">Nama Perawat</label>
                                                <b-form-input v-model="isParent.diagnosa[k]['aaksd_nama_perawat']"
                                                    :formatter="normalText"  placeholder="Tulis Nama" />
                                            </div>
                                            
                                            <div class="form-group col-6">
                                                <label for="addInfoTindakan">Waktu Tindakan</label>
                                                <vue-timepicker v-model="isParent.diagnosa[k].aaksd_tindakan_pada" format="HH:mm"  input-class="form-control"></vue-timepicker>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="addInfoTindakan">Tindakan Keperawatan</label>
                                            <b-textarea v-model="isParent.diagnosa[k]['aaksd_informasi_tindakan']"
                                                :formatter="normalText" rows="6" class="form-control"
                                                placeholder="Tindakan Keperawatan">
                                            </b-textarea>


                                            <VValidate :name="`Tindakan Keperawatan #${k+1}`"
                                                v-model="isParent.diagnosa[k].aaksd_informasi_tindakan"
                                                :rules="{min: 2}" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border shadow-0" v-if="isParent.rowReg.mcp_is_move == 'Y'">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Tindak Lanjut Poli</h5>
            </div>
            <div class="card-body">

                <b-form-group>
                    <label>Apakah pasien akan dipindahkan ke poli lain?<span class="text-danger mr5">*</span></label>
                    <b-form-radio-group :options="Config.mr.isMovePoli" v-model="isParent.row.is_move_poli" />
                </b-form-group>

                <div class="form-row" v-if="isParent.row.is_move_poli == 'Y'">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="poliSelection">Pilih Poli</label>

                            <v-select placeholder="Pilih Poli" v-model="isParent.row.newPoli"
                                @input="selectDokter($event)" :options="isParent.mPoli" label="text" :clearable="true"
                                :reduce="v=>v.value"></v-select>

                            <VValidate :name="`Poli`" v-model="isParent.row.newPoli" :rules="{required: 1}" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="docSelection">Pilih Dokter</label>

                            <v-select placeholder="Pilih Dokter" v-model="isParent.row.newDokter"
                                :options="isParent.mDokter" label="text" :clearable="true" :reduce="v=>v.value">
                            </v-select>

                            <VValidate :name="`Dokter`" v-model="isParent.row.newDokter" :rules="{required: 1}" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border shadow-0" v-if="isParent.row.isEdit">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label for="addInfoTindakan">Keterangan Perubahan</label>
                    <b-textarea v-model="isParent.row.arm_notes" :formatter="normalText" rows="6" class="form-control"
                        placeholder="Keterangan Perubahan">
                    </b-textarea>


                    <VValidate :name="`Keterangan Perubahan`" v-model="isParent.row.arm_notes"
                        :rules="{required: 1, min: 2, max:512}" />
                </div>
            </div>
        </div>


        <b-modal v-model="openFollowUpTindakan" :title="'Penunjang Rekam Medis'" size="sm" ok-title="Submit"
            @ok="submitFollowUp">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Follow Up Tindakan<small class="txt_mandatory">*</small></label>
                        <b-textarea v-model="rowFollowUp.followup" :formatter="normalText" rows="6" class="form-control"
                            placeholder="Informasi Tindakan" spellcheck="false"></b-textarea>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
    extends: GlobalVue,
    components:{ Datepicker,VueTimepicker },
    methods: {

        ceilData(value){
          return Math.ceil(value)
        },
        
        getMasterSDKI(master,diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.aaksd_diagnosa_id
            })
            return _.filter(master, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },

        getMasterSLKI(master,diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.aaksl_diagnosa_luaran_id
            })
            return _.filter(master, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },

        getMasterSIKI(master,diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.aaksi_diagnosa_intervensi_id
            })
            return _.filter(master, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },

        getMasterKriteriaHasil(master,kriteriaHasil,k){
            const sldMenu = _.map(_.filter(kriteriaHasil||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.name
            })


            return _.filter(master, v2=>{
                return sldMenu.indexOf(v2.mks_criteria_hasil)<=-1
            })
        },

        addTindakanKeperawtan(e,name,type,k,id,idSiki){
            // add
            if(e){
                this.isParent.diagnosa[k].aaksd_tindakan_keperawatan.push({
                    name :`(${type}) ${name}`,
                    dates : moment().format('YYYY-MM-DD'),
                    time : {
                        'HH': moment().format('HH'),
                        'mm': moment().format('mm')
                    },
                    ppaLainnya: null,
                    ppa : "Perawat",
                    followup : "",
                    id: id,
                    idSiki: idSiki
                })
            }else{
                let index = this.isParent.diagnosa[k].aaksd_tindakan_keperawatan.findIndex(x=>x.id == id)
                if(index !== -1){
                    this.isParent.diagnosa[k].aaksd_tindakan_keperawatan.splice(index, 1)
                }
            }
        },
        
        changePos(k){
            let sortedObjs = _.sortBy(this.isParent.diagnosa[k].aaksd_tindakan_keperawatan, 'dates')
            this.isParent.diagnosa[k].aaksd_tindakan_keperawatan = sortedObjs
        },

        toValidate(val){
            return {...val}
        },

        changeTab(k){
            this.isActiveTab = k
        },

        changeTabSLKI(k1,k){
            this.isParent.diagnosa[k].aaksd_last_tab_slki = k1
            this.isParent.autoSave(this.isParent.row)
        },
        
        changeTabSIKI(k2,k){
            this.isParent.diagnosa[k].aaksd_last_tab_siki = k2
            this.isParent.autoSave(this.isParent.row)        
        },
        
        addNewKriteria(k,k1){
            this.isParent.diagnosa[k]['slki'][k1]['aaksl_kriteria_hasil'].push({
                hasil : 1,
                name : ''  
            })
        },
        deleteKriteria(k,k1,k11){
            this.isParent.diagnosa[k]['slki'][k1]['aaksl_kriteria_hasil'].splice(k11,1)
        },

        addNewDiagnosa(){
            this.isParent.$parent.loadingOverlay = true
            let data = {
                aaksd_arm_id: this.isParent.row.arm_id,
                type: 'add-diagnosa'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                resp.row.aaksd_last_tab_slki = 0
                resp.row.aaksd_last_tab_siki = 0
                this.isParent.diagnosa.push(resp.row)
                this.isActiveTab = this.isParent.diagnosa.length
                this.isParent.$parent.loadingOverlay = false
            })
        },
        addLuaran(diagnosa, arm_id, aaksd_id, k){
            this.isParent.$parent.loadingOverlay = true
            let data = {
                aaksl_diagnosa_id: diagnosa,
                aaksl_aaksd_id: aaksd_id,
                aaksl_arm_id: arm_id,
                aaksl_kriteria_hasil: [],
                type: 'add-luaran'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.isParent.diagnosa[k]['slki'].push(resp.row)
                this.isParent.diagnosa[k]['aaksd_last_tab_slki'] = resp.countBySDKI
                this.isParent.diagnosa[k]['mSlki'] = resp.mSlki
                this.isParent.$parent.loadingOverlay = false
            })
        },
        addIntervensi(diagnosa, arm_id, aaksd_id, k){
            this.isParent.$parent.loadingOverlay = true
            let data = {
                aaksi_diagnosa_id: diagnosa,
                aaksi_aaksd_id: aaksd_id,
                aaksi_arm_id: arm_id,
                aaksi_tindakan_observasi: [],
                aaksi_tindakan_terapeutik: [],
                aaksi_tindakan_edukasi: [],
                aaksi_tindakan_kolaborasi: [],
                aaksi_data: [],
                aaksi_tindakan_tambahan: [],

                type: 'add-intervensi'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.isParent.diagnosa[k]['siki'].push(resp.row)
                this.isParent.diagnosa[k]['aaksd_last_tab_siki'] = resp.countBySDKI
                this.isParent.diagnosa[k]['mSiki'] = resp.mSiki
                this.isParent.$parent.loadingOverlay = false
            })
        },
        selectedIntervensi(e,k,k2){
            let index = this.isParent.diagnosa[k]['mSiki'].findIndex(x=>x.value == e)
            if(index !== -1){            
                this.isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_observasi'] = this.isParent.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_observasi']
                
                this.isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_terapeutik'] = this.isParent.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_terapeutik']
                
                this.isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_edukasi'] = this.isParent.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_edukasi']
                
                this.isParent.diagnosa[k]['siki'][k2]['aaksi_tindakan_kolaborasi'] = this.isParent.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_kolab']

                this.isParent.diagnosa[k]['siki'][k2]['text'] = this.isParent.diagnosa[k]['mSiki'][index]['text']
            }
        },

        removeDiagnosa(id,k){
            this.isParent.$parent.loadingOverlay = true
            let data = {
                id: id,
                type: 'remove-diagnosa'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
                this.isParent.diagnosa.splice(k,1)
                this.isActiveTab = this.isParent.diagnosa.length
                this.isParent.$parent.loadingOverlay = false
            })
        },

        removeLuaran(id,k,k1){
            this.isParent.$parent.loadingOverlay = true
            let data = {
                id: id,
                type: 'remove-luaran'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
                this.isParent.diagnosa[k]['slki'].splice(k1,1)
                this.isParent.$parent.loadingOverlay = false
                this.isParent.diagnosa[k]['aaksd_last_tab_slki'] = this.isParent.diagnosa[k]['slki'].length   
            })
        },

        removeIntervensi(id,k,k2,intervensiId){
            this.isParent.$parent.loadingOverlay = true
            
            let dataTindakan = _.filter(this.isParent.diagnosa[k].aaksd_tindakan_keperawatan, v => {
                return v.idSiki == intervensiId
            })
            
            for(let i = 0; i < (dataTindakan||[]).length; i++){
                let index = this.isParent.diagnosa[k].aaksd_tindakan_keperawatan.findIndex(x=>x.idSiki == intervensiId)
                if(index !== -1){
                    this.isParent.diagnosa[k].aaksd_tindakan_keperawatan.splice(index, 1)
                }
            }

            let data = {
                id: id,
                type: 'remove-intervensi'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
                this.isParent.diagnosa[k]['siki'].splice(k2,1)
                this.isParent.$parent.loadingOverlay = false
                this.isParent.diagnosa[k]['aaksd_last_tab_siki'] = this.isParent.diagnosa[k]['siki'].length          
            })
        },

        getLabelSDKI(data = null){
            if(data){
                let index = this.isParent.mSDKI.findIndex(x=>x.value == data)
                if(index !== -1){
                    data = this.isParent.mSDKI[index]['text']
                }
            }
            return data
        },

        selectPenyebab(e,k){
            if(e){
                let index = this.isParent.mSDKI.findIndex(x=>x.value == e)
                if(index !== -1){
                    this.isParent.diagnosa[k]['aaksd_config_sdki'] = this.isParent.mSDKI[index]
                    this.isParent.diagnosa[k]['text'] = this.isParent.mSDKI[index]['text']
                    
                    for(let i = 0; i < (this.isParent.diagnosa[k]['slki']||[]).length; i++){
                        this.removeLuaran(this.isParent.diagnosa[k]['slki'][i].aaksl_id,k,i)                  
                        
                    }
                    
                    for(let i = 0; i < (this.isParent.diagnosa[k]['siki']||[]).length; i++){
                        this.removeIntervensi(this.isParent.diagnosa[k]['siki'][i].aaksi_id,k,i)
                    }
                    
                }
            }
        },

        getLabelSLKI(data,id){
            let text = ""
            if(id){
                let index = data.findIndex(x=>x.value == id)
                if(index !== -1){
                    text = data[index]['text']
                }
            }
            return text
        },
        

        getLabelSIKI(data,id){
            let text = ""
            if(id){
                let index = data.findIndex(x=>x.value == id)
                if(index !== -1){
                    text = data[index]['text']
                }
            }
            return text
        },
        openDetailFollowUp(v,k,kP){
            this.openFollowUpTindakan = true,
            this.rowFollowUp = v
            this.rowFollowUp.key = k
            this.rowFollowUp.keyParent = kP
        },

        submitFollowUp(){
            this.diagnosa[this.rowFollowUp.keyParent]['aaksd_tindakan_keperawatan'][this.rowFollowUp.key]['followup'] = this.rowFollowUp.followup
        },

        selectedKriteriaHasil(e,k,k1){
            let index = this.isParent.diagnosa[k]['mSlki'].findIndex(x=>x.value == e)
            if(index !== -1){            
                this.isParent.diagnosa[k]['slki'][k1]['text'] = this.isParent.diagnosa[k]['mSlki'][index]['text']
            }

            let data = {
                id: e,
                type: 'get-kriteria-hasil'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.isParent.diagnosa[k]['slki'][k1]['kriteriaHasil'] = res.data.mKriteriaHasil            
            })
        },
        
        selectDokter(e){
            let data = {
                id: e,
                type: 'get-by-poli'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.isParent.mDokter = res.data.mDokter            
            })
        },

        changePageSDKI(page,k,act,col){
            if(act == 'min'){
                if(page > 1){
                    page -= 1
                    this.isParent[col][k]['currentPage'] -= 1 
                }
            }else{
                if(page < this.ceilData(this.isParent[col][k]['totalRows']/10)){
                    page += 1
                    this.isParent[col][k]['currentPage'] += 1 
                }
            }
            let data = {
                type : 'select-paging-sdki',
                page : page,
                search : this.isParent[col][k]['search']
            }

            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.isParent.mSDKI = res.data.data
            })
        },
        
        filterSDKI: _.debounce(function (e,k){
            if(e){
                this.isParent.diagnosa[k]['search'] = e         
                Gen.apiRest('/do/'+'RekamMedis', {data: {type:'select-paging-sdki', search: e}}, 'POST').then(res=>{
                    this.isParent.mSDKI = res.data.data
                    this.isParent.diagnosa[k]['currentPage'] = 1
                    this.isParent.diagnosa[k]['totalRows'] = res.data.totalRows
                })
            }
        },10),

        
        changePageSLKI(page,k,k1,act,col,diagnosa){
             if(act == 'min'){
                if(page > 1){
                    page -= 1
                    this.isParent[col][k][diagnosa][k1]['currentPage'] -= 1 
                }
            }else{
                if(page < this.ceilData(this.isParent[col][k][diagnosa][k1]['totalRows']/10)){
                    page += 1
                    this.isParent[col][k][diagnosa][k1]['currentPage'] += 1 
                }
            }
            let data = {
                type : 'select-paging-slki',
                page : page,
                diagnosa_id: this.isParent[col][k]['aaksd_diagnosa_id'],
                search : this.isParent[col][k]['search']
            }

            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.isParent.mSLKI = res.data.data
            })
        },

        filterSLKI: _.debounce(function (e,k,k1){
            if(e){
                this.isParent.diagnosa[k]['slki'][k1]['search'] = e         
                Gen.apiRest('/do/'+'RekamMedis', {data: {type:'select-paging-slki', search: e, diagnosa_id: this.isParent['diagnosa'][k]['aaksd_diagnosa_id']}}, 'POST').then(res=>{
                    this.isParent.mSLKI = res.data.data
                    this.isParent.diagnosa[k]['slki'][k1]['currentPage'] = 1
                    this.isParent.diagnosa[k]['slki'][k1]['totalRows'] = res.data.totalRows
                })
            }
        },10),

        changePageSIKI(page,k,k2,act,col,diagnosa){
             if(act == 'min'){
                if(page > 1){
                    page -= 1
                    this.isParent[col][k][diagnosa][k2]['currentPage'] -= 1 
                }
            }else{
                if(page < this.ceilData(this.isParent[col][k][diagnosa][k2]['totalRows']/10)){
                    page += 1
                    this.isParent[col][k][diagnosa][k2]['currentPage'] += 1 
                }
            }
            let data = {
                type : 'select-paging-siki',
                page : page,
                diagnosa_id: this.isParent[col][k]['aaksd_diagnosa_id'],
                search : this.isParent[col][k]['search']
            }

            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.isParent.mSIKI = res.data.data
            })
        },
        
        filterSIKI: _.debounce(function (e,k,k2){
            if(e){
                this.isParent.diagnosa[k]['siki'][k2]['search'] = e         
                Gen.apiRest('/do/'+'RekamMedis', {data: {type:'select-paging-siki', search: e, diagnosa_id: this.isParent['diagnosa'][k]['aaksd_diagnosa_id']}}, 'POST').then(res=>{
                    this.isParent.mSIKI = res.data.data
                    this.isParent.diagnosa[k]['siki'][k2]['currentPage'] = 1
                    this.isParent.diagnosa[k]['siki'][k2]['totalRows'] = res.data.totalRows
                })
            }
        },10),


        
    },
    data() {
        return {
            isActiveTab : null,
            openFollowUpTindakan : false,
            rowFollowUp: {} 
        }
    },
    mounted() {
        if((this.isParent.diagnosa||[]).length){
            this.isActiveTab = (this.isParent.diagnosa||[]).length
        }
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    },
    watch:{
        'isParent.row.newPoli':function(v){
            if(!v){
                this.isParent.row.newDokter = null
            }
        }
    }
}
</script>

