<template>

    <!-- Pasien berusia < 15 tahun -->
    
    <div class="card" v-if="isParent.row.ap_usia < 15">
        <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Screening Gizi metode STRONGkids</h5>
        </div>
        <div class="card-body">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table">
                            <thead>
                                <tr>
                                    <th>PARAMETER</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in (isParent.row.asg_data||[])" :key="k">
                                    <td>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <b-form-checkbox :value="v.valueVal" :unchecked-value="null"
                                                    v-model="isParent.row.asg_data[k]['value']" class="form-check-input-styled"
                                                    name="radio-inline-left" />
                                                {{v.name}}
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="alert alert-warning">Jika ada jawaban "YA", akan dilakukan pengkajian oleh ahli gizi</div>
                    </div>
                    <!--
                <div class="col-12">
                <div class="alert alert-info">Sudah dibaca dan diketahui oleh ahli gizi tanggal: <span class="font-weight-semibold">23 Mar 2023 17:12</span></div>
                </div>
                -->
                </div>
            </div>
        </div>
    </div>
        

    <div class="card" v-else>
        <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Screening Gizi Metode Malnutrition Screening Tools</h5>
        </div>
        <div class="card-body">
            <div class="form-group">
                <label for="">Apakah Pasien Mengalami Penurunan Berat Badan yang Tidak Diinginkan Selama 6 Bulan
                    Terakhir?<strong class="text-danger">*</strong></label>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>PARAMETER</th>
                                    <th>SKOR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in isParent.mSkrininGizi" :key="k+'a'">
                                    <td>
                                        <b-form-radio class="form-check-input-styled" @change="toggleShowScreening($event)"
                                            :value="v.idVal" v-model="isParent.row.asg_value_label" name="nutParams">{{v.name}}
                                        </b-form-radio>
                                    </td>
                                    <td>{{v.valueVal}}</td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <b-form-radio name="nutParams" v-model="showScreeningWeight"
                                            @change="toggleShowScreening($event)">
                                            Ya, dengan penurunan berat
                                        </b-form-radio>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="showScreeningWeight">
                                <tr v-for="(v,k) in isParent.mSkrininGiziV2" :key="k+'b'">
                                    <td>
                                        <div class="pl-3">
                                            <b-form-radio class="form-check-input-styled" :value="v.idVal"
                                                v-model="isParent.row.asg_value_label" name="nutParams2">{{v.name}}
                                            </b-form-radio>
                                        </div>
                                    </td>
                                    <td>{{v.valueVal}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="table-info">
                                    <td colspan="99" class="text-info-700">
                                        <span>NILAI SKALA GIZI: {{ isParent.isToGiziAdult }}</span>
                                        <div class="d-inline-block border-left ml-2 pl-2 font-weight-semibold">
                                            <span v-if="isParent.isToGiziAdult > 1">TINGGI</span>
                                            <span v-else>RENDAH</span>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <!-- <div class="col-md-6">
                        <table class="table table-bordered table-striped  table-sm patient-table">
                            <thead>
                                <tr>
                                    <th>SKOR: </th>
                                    <th class="table-danger">{{isParent.isToGiziAdult}}</th>
                                    <th class="table-danger">{{isParent.isToGiziAdult > 1 ? 'TINGGI' : 'RENDAH'}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div> -->
                    <div class="col-12">
                        <div class="alert alert-info">Skor lebih atau sama dengan 2 akan dilakukan kajian lanjut oleh dietisen
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="">Apakah asupan makanan berkurang karena tidak nafsu makan?</label>
                            <div>

                                <b-form-radio-group :options="Config.mr.yesNoOptV2" v-model="isParent.row.asg_is_nafsu_makan" />

                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Diagnosis Khusus Pasien</label>
                            <div class="form-row">
                                <div class="col-md-3">
                                    <v-select placeholder="Pilih Diagnosis Khusus" v-model="isParent.row.asg_diagnosis_khusus"
                                        :options="isParent.Config.mr.diagnosaKhusus" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                </div>
                                <div class="col-md-4" v-if="isParent.row.asg_diagnosis_khusus == 'L'">
                                    <b-form-input :formatter="normalText" v-model="isParent.row.asg_diagnosis_khusus_lainnya"
                                        type="text" class="form-control" placeholder="Isi Diagnosa Khusus" />
                                    <VValidate name="Psikologi Pasien Lainnya"
                                        v-model="isParent.row.asg_diagnosis_khusus_lainnya"
                                        :rules="toValidate(isParent.mrValidation.asg_diagnosis_khusus_lainnya)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    const _ = global._

    import Gen from '@/libs/Gen.js'
    import GlobalVue from '@/libs/Global.vue'
    // const moment = require('moment')

    export default {
        extends: GlobalVue,
        data() {
            return {
                showScreeningWeight: false,
                showScreening: '',
            }
        },
        computed: {
            isParent() {
                return this.$parent.$parent.$parent.$parent
            },
        },
        mounted() {
            if(this.isParent.row.asg_value_label > 2){
                this.showScreeningWeight = true
            }else{
                this.showScreeningWeight = false
            }

        },
        methods: {
            toValidate(val) {
                return {
                    ...val
                }
            },
            toggleShowScreening(e) {
                e ? this.showScreeningWeight = false : this.showScreeningWeight = true
            }
        }
    }
</script>