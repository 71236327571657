<template>
    <div class="row g-2">
    <!-- Skala Nyeri jika pasien berusia lebih dari sama dengan 6 tahun -->
    <div class="col-12" v-if="isParent.row.ap_usia > 6">
        <div class="card">
            <div class="card-header bg-info">
                <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
            </div>
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-md-auto">
                        <label for="perPain">Skala Nyeri<strong class="text-danger">*</strong></label>
                        <div>
                            <div v-for="(v,k) in Config.mr.skalaNyeri" :key="k" :class="`pain-scale-radio ${v.class}`">
                            <input type="radio" v-model="isParent.row.akn_skala_nyeri_value" name="perPainScale" :id="`perPainScale${v.text}`" :value="v.value" class="psr-input">
                            <label :for="`perPainScale${v.text}`" class="psr-label">
                                <span class="psr-label-content">{{v.text}}</span>
                                <div class="psr-check-container"></div>
                            </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="pain-scale no-pain" v-if="isParent.row.akn_skala_nyeri_value == 0">
                            <img :src="assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Tidak Ada Nyeri</strong>
                        </div>
                        <div class="pain-scale mild-pain" v-if="isParent.row.akn_skala_nyeri_value >= 1 && isParent.row.akn_skala_nyeri_value <= 3">
                            <img :src="assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Sedikit Nyeri</strong>
                        </div>
                        <div class="pain-scale moderate-pain" v-if="isParent.row.akn_skala_nyeri_value >= 4 && isParent.row.akn_skala_nyeri_value <= 5">
                            <img :src="assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Agak Mengganggu</strong>
                        </div>
                        <div class="pain-scale severe-pain" v-if="isParent.row.akn_skala_nyeri_value >= 6 && isParent.row.akn_skala_nyeri_value <= 7">
                            <img :src="assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Mengganggu Aktivitas</strong>
                        </div>
                        <div class="pain-scale worst-pain" v-if="isParent.row.akn_skala_nyeri_value >= 8 && isParent.row.akn_skala_nyeri_value <= 9">
                            <img :src="assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Sangat Mengganggu</strong>
                        </div>
                        <div class="pain-scale worst-pain" v-if="isParent.row.akn_skala_nyeri_value >= 10">
                            <img :src="assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                            <strong>Tak Tertahankan</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Skala Nyeri jika pasien berusia kurang dari 6 tahun -->
    <div class="col-12" v-else>

        <div class="card">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">FLACC Scale</h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                <label for="kidPainScale">Skala Nyeri<strong class="text-danger">*</strong></label>
                <table class="table table-bordered pain-scale-table">
                    <thead>
                    <tr>
                        <th>Pengkajian</th>
                        <th>Nilai 0</th>
                        <th>Nilai 1</th>
                        <th>Nilai 2</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v,k) in (isParent.row.akn_skala_nyeri||[])" :key="k">
                        <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                        <td v-for="(vc,kc) in v.configVal" :key="kc">
                        <div class="form-check form-check-inline align-middle">
                        <label class="form-check-label" :for="v.name+k+kc">
                            <b-form-radio type="radio" :value="vc.value" v-model="isParent.row.akn_skala_nyeri[k]['value']" :id="v.name+k+kc" class="form-check-input-styled" />{{vc.text}}
                        </label>
                        </div>
                        
                        <VValidate 
                            :name="v.name" 
                            v-model="isParent.row.akn_skala_nyeri[k]['value']" 
                            :rules="{required:1}"
                        /> 
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="table-info">
                        <td colspan="4" class="text-info-700">
                        <span>SKALA: {{isParent.hitungSkala}} </span>
                        
                        <span v-if="isParent.hitungSkala == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                        <span v-else-if="isParent.hitungSkala >= 1 && isParent.hitungSkala < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                        <span v-else-if="isParent.hitungSkala >= 4 && isParent.hitungSkala < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                        </td>
                    </tr>
                    </tfoot>
                </table>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// const moment = require('moment')

export default{
    extends: GlobalVue,
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    },
    methods: {
        toValidate(val){
            return {...val}
        },
    }
}
</script>