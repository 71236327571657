<template>
    <div class="content">
      <validation-observer ref="VFormDokterGizi">
      <b-form @submit.prevent="doSubmitDokterGizi">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pengkajian Gizi</h6>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_line">
                    <template v-if="row.ap_usia > 15">
                    <h3> <span>Anthropometri (Pasien Dewasa)</span></h3>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>BB<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input @input="hitungIMT();hitungBBU();hitungBBTB()" :formatter="$parent.number" v-model="row.apg_bb" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">kg</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="BB" 
                                      v-model="row.apg_bb" 
                                      :rules="toValidate(mrValidation.apg_bb)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>TB<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input @input="hitungBBI();hitungIMT();hitungTBU();hitungBBTB()" :formatter="$parent.number" v-model="row.apg_tb" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cm</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="TB" 
                                      v-model="row.apg_tb" 
                                      :rules="toValidate(mrValidation.apg_tb)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2" v-if="(row.ap_usia > 15)">
                              <div class="form-group">
                                  <label>BBI<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                          <div class="input-group">
                                              <b-form-input disabled :formatter="$parent.number" v-model="row.apg_bbi" type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">kg</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="BBI" 
                                      v-model="row.apg_bbi" 
                                      :rules="toValidate(mrValidation.apg_bbi)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>IMT<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                          <div class="input-group">
                                              <b-form-input disabled :formatter="$parent.number" v-model="row.apg_imt" type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">kg/m2</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="IMT" 
                                      v-model="row.apg_imt" 
                                      :rules="toValidate(mrValidation.apg_imt)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>LiLA</label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input @input="hitungLLAU" :formatter="$parent.number" v-model="row.apg_lla" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cm</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="LiLA" 
                                      v-model="row.apg_lla" 
                                      :rules="toValidate(mrValidation.apg_lla)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>TL<small class="txt_mandatory" v-if="(row.ap_usia >= 2 &&  row.ap_usia <= 15) || !row.apg_tb">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" v-model="row.apg_tl" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cm</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      v-if="(row.ap_usia >= 2 &&  row.ap_usia <= 15) || !row.apg_tb"
                                      name="TL" 
                                      v-model="row.apg_tl" 
                                      :rules="toValidate(mrValidation.apg_tl)"
                                  />
                              </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    </template>

                      <!--v-if="isParent.row.ap_usia <= 15"-->
                    <template v-else>
                    <h3> <span>Anthropometri (Pasien Anak)</span></h3>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>BB<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input @input="hitungIMT();hitungBBU();hitungBBTB()" :formatter="$parent.number" v-model="row.apg_bb" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">kg</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="BB" 
                                      v-model="row.apg_bb" 
                                      :rules="toValidate(mrValidation.apg_bb)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>TB<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input @input="hitungBBI();hitungIMT();hitungTBU();hitungBBTB()" :formatter="$parent.number" v-model="row.apg_tb" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cm</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="TB" 
                                      v-model="row.apg_tb" 
                                      :rules="toValidate(mrValidation.apg_tb)"
                                  />
                              </div>
                          </div>


                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>LiLA<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" @input="hitungLLAU()" v-model="row.apg_lla" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cm</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="LiLA" 
                                      v-model="row.apg_lla" 
                                      :rules="toValidate(mrValidation.apg_lla)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>LK</label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" v-model="row.apg_lk" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">cm</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="LK" 
                                      v-model="row.apg_lk" 
                                      :rules="toValidate(mrValidation.apg_lk)"
                                  />
                              </div>
                          </div>


                          <div class="col-md-2"  v-if="(row.ap_usia > 15)">
                              <div class="form-group">
                                  <label>BBI<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                          <div class="input-group">
                                              <b-form-input disabled :formatter="$parent.number" v-model="row.apg_bbi" type="text" class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">kg</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="BBI" 
                                      v-model="row.apg_bbi" 
                                      :rules="toValidate(mrValidation.apg_bbi)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                                  <label>BB/U<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" v-model="row.apg_bbu" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">%</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="BB/U" 
                                      v-model="row.apg_bbu" 
                                      :rules="toValidate(mrValidation.apg_bbu)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>TB/U<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" v-model="row.apg_tbu" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">%</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="TB/U" 
                                      v-model="row.apg_tbu" 
                                      :rules="toValidate(mrValidation.apg_tbu)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>BB/TB<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" v-model="row.apg_bbtb" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">%</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="BB/TB" 
                                      v-model="row.apg_bbtb" 
                                      :rules="toValidate(mrValidation.apg_bbtb)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-2">
                              <div class="form-group">
                                  <label>LiLA/U<small class="txt_mandatory">*</small></label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="$parent.number" v-model="row.apg_llau" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">%</span>
                                          </div>
                                      </div>
                                      </div>
                                  </div>
                                  <VValidate 
                                      name="LiLA/U" 
                                      v-model="row.apg_llau" 
                                      :rules="toValidate(mrValidation.apg_llau)"
                                  />
                              </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label>HA<small class="txt_mandatory">*</small></label>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="input-group">
                                      <b-form-input :formatter="$parent.number" v-model="row.apg_ha" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">th</span>
                                      </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="input-group">
                                    <b-form-input :formatter="$parent.number" v-model="row.apg_bln" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">bln</span>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <VValidate 
                                  name="HA" 
                                  v-model="row.apg_ha" 
                                  :rules="toValidate(mrValidation.apg_ha)"
                              />
                              <VValidate 
                                  name="Bln" 
                                  v-model="row.apg_bln" 
                                  :rules="toValidate(mrValidation.apg_bln)"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row">

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Biokimia</label>
                              <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_biokimia']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                              
                              <VValidate 
                                  name="Biokimia" 
                                  v-model="row.apg_biokimia" 
                                  :rules="toValidate(mrValidation.apg_biokimia)"
                              />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Fisik</label>
                              <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_fisik']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                              
                              <VValidate 
                                  name="Fisik" 
                                  v-model="row.apg_fisik" 
                                  :rules="toValidate(mrValidation.apg_fisik)"
                              />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Klinis</label>
                              <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_klinis']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                              
                              <VValidate 
                                  name="Klinis" 
                                  v-model="row.apg_klinis" 
                                  :rules="toValidate(mrValidation.apg_klinis)"
                              />
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    </template>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="wrap_line">
                    <h3> <span>Riwayat Gizi</span></h3>
                    <div class="row">
                      <div class="col-md-7">
                        <div class="head_panel_red mt-2">
                          <div class="row">
                            <div class="col-md-7">
                              <h3>ALERGI MAKANAN </h3>
                            </div>
                            <div class="col-md-5">
                          <div class="form-group mb-0 mt-1">
                                <div class="clearfix"></div>
                                  <b-form-radio-group
                                      
                                      :options="Config.mr.yesNoOptV2"
                                      v-model="row.apg_has_alergi"
                                  />
                                  <VValidate 
                                      name="Alergi" 
                                      v-model="row.apg_has_alergi" 
                                      :rules="toValidate(mrValidation.apg_has_alergi)"
                                  />
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>

                      <div class="col-md-5" v-if="row.apg_has_alergi == 'Y'">
                        <div class="form-group">
                          <label>Makanan<small class="txt_mandatory">*</small></label>
                          <b-form-input v-model="row.apg_makanan" type="text" name="name" class="form-control" />
                          <VValidate 
                              name="Makanan" 
                              v-model="row.apg_makanan" 
                              :rules="toValidate(mrValidation.apg_makanan)"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Pola Makan<small class="txt_mandatory">*</small></label>
                          <b-form-input v-model="row.apg_pola_makanan" type="text" name="name" class="form-control" />
                          <VValidate 
                              name="Pola Makan" 
                              v-model="row.apg_pola_makanan" 
                              :rules="toValidate(mrValidation.apg_pola_makanan)"
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Total Asupan<small class="txt_mandatory">*</small></label>
                          <b-form-input v-model="row.apg_total_asupan" type="text" name="name" class="form-control" />
                          <VValidate 
                              name="Total Asupan" 
                              v-model="row.apg_total_asupan" 
                              :rules="toValidate(mrValidation.apg_total_asupan)"
                          />
                        </div>
                      </div>

                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                              <tr>
                                <th>Zat Gizi</th>
                                <th>Asupan Aktual</th>
                                <th>Kebutuhan</th>
                                <th>% Kebutuhan</th>
                                <th>Kategori Asupan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (row.apg_zat_gizi||[])" :key="k+'gizi'">
                                <td>{{v.name||"-"}}</td>
                                <td> <b-form-input v-model="row.apg_zat_gizi[k].asupan" :formatter="$parent.number" @input="hitungKebutuhan(k)" type="text" name="name" class="form-control" />
                                </td>
                                <td> <b-form-input v-model="row.apg_zat_gizi[k].kebutuhan" :formatter="$parent.number" @input="hitungKebutuhan(k)" type="text" name="name" class="form-control" />
                                </td>
                                <td> <b-form-input disabled v-model="row.apg_zat_gizi[k].kebutuhan_val" :formatter="$parent.number" type="text" name="name" class="form-control" />
                                </td>
                                <td v-if="k == 0" rowspan="4">
                                  <div class="form-group mb-0">
                                      <b-form-radio-group
                                          
                                          :options="Config.mr.kategoriAsupan"
                                          style="display: block;width: 100px;"
                                          v-model="row.apg_kategori_asupan"
                                      />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                <div class="col-md-12">
                  <div class="wrap_line">
                    <h3> <span>Riwayat Personal</span></h3>
                    <div class="row">
                      

                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Riwayat Personal<small class="txt_mandatory">*</small></label>
                          <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_riwayat_personal']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                          <VValidate 
                              name="Riwayat Personal" 
                              v-model="row.apg_riwayat_personal" 
                              :rules="toValidate(mrValidation.apg_riwayat_personal)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-12 mb-2">
                    <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Diagnosa dan Tindakan</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-md">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th width="40%">Diagnosis</th>
                                        <th width="10%">Hapus</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v,k) in dataGiziDiagnosa" :key="'diagnosa'+k">
                                        <td>
                                        <div class="diagnoseElement form-group"  data-doc-diagnose="GiziDiagnosa">
                                            <label for="">Diagnosis</label>
                                            <v-select placeholder="Pilih Diagnosa" :class="contentLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen" @search="countLen" v-model="dataGiziDiagnosa[k].apgd_diagnosa_id"
                                            :options="getmGiziDiagnosa(dataGiziDiagnosa,k)" @input="selectGiziDiagnosa($event,k)" label="text" :clearable="true"
                                            :reduce="v=>v.value">
                                                <template slot="selected-option" slot-scope="option">
                                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                                </template>
                                                <template slot="option" slot-scope="option">
                                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="diagnoseElement form-group" data-doc-diagnose="text">
                                            <label for="">Diagnosis <small class="txt_mandatory">*</small></label>

                                            <b-textarea v-model="dataGiziDiagnosa[k].apgd_diagnosa" :formatter="$parent.normalText"
                                            name="diagnoseContent" id="diagnoseContent" rows="2" placeholder="Isi diagnosa pasien"
                                            class="form-control"></b-textarea>

                                            <VValidate :name="`Diagnosa #${k+1}`" v-model="dataGiziDiagnosa[k].apgd_diagnosa"
                                            :rules="{required:1, min:2, max:512}" />
                                        </div>
                                        </td>
                                        <td>
                                        <a v-if="dataGiziDiagnosa.length > 1" href="javascript:;" class="list-icons-item"
                                            @click="removedataGiziDiagnosa(v.apgd_id, k)" data-toggle="tooltip" data-placement="top"
                                            title="Delete"><i class="icon-bin"></i></a>
                                        <span v-else> - </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colspan="2" class="text-center">
                                        <a href="javascript:;" @click="addNewGiziDiagnosa" class="btn alpha-blue">
                                            <i class="icon-plus2 mr-2"></i>
                                            Tambah Diagnosa
                                        </a>
                                        <a href="javascript:;" v-if="riwayatGiziDiagnosa.length" @click="openriwayatGiziDiagnosa = true"
                                            class="btn alpha-orange ml-1" data-toggle="modal" data-target="#modalResepRiwayat">
                                            <i class="icon-plus2 mr-1 align-middle"></i>
                                            <span class="align-middle">Pilih dari Riwayat</span>
                                        </a>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                                </div>

                                <div class="col-md">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th width="40%">Intervensi</th>
                                        <th width="10%">Hapus</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v,k) in dataGiziTindakan" :key="'diagnosa'+k">
                                        <td>
                                        <div class="diagnoseElement form-group" data-doc-diagnose="GiziDiagnosa">
                                            <label for="">Intervensi</label>

                                            <v-select placeholder="Pilih Intervensi" 
                                            :class="contentLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen" @search="countLen"
                                            @input="selectGiziTindakan($event,k)"
                                            v-model="dataGiziTindakan[k].apgt_tindakan_id" :options="getmGiziTindakan(dataGiziTindakan,k)" label="text"
                                            :clearable="true" :reduce="v=>v.value">
                                            <template slot="selected-option" slot-scope="option">
                                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                            </template>
                                            </v-select>
                                        </div>
                                        <div class="diagnoseElement form-group" data-doc-diagnose="text">
                                            <label for="">Intervensi</label>
                                            <b-textarea v-model="dataGiziTindakan[k].apgt_tindakan" :formatter="$parent.normalText"
                                            name="diagnoseContent" id="diagnoseContent" rows="2" placeholder="Isi Intervensi pasien"
                                            class="form-control"></b-textarea>
                                        </div>
                                        </td>
                                        <td>
                                        <a v-if="dataGiziTindakan.length > 1" href="javascript:;" class="list-icons-item"
                                            @click="removedataGiziTindakan(v.apgt_id, k)" data-toggle="tooltip" data-placement="top"
                                            title="Delete"><i class="icon-bin"></i></a>
                                        <span v-else> - </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colspan="2" class="text-center">
                                        <a href="javascript:;" @click="addNewGiziTindakan" class="btn alpha-blue">
                                            <i class="icon-plus2 mr-2"></i>
                                            Tambah Tindakan
                                        </a>
                                        <a href="javascript:;" v-if="riwayatGiziTindakan.length" @click="openriwayatGiziTindakan = true"
                                            class="btn alpha-orange ml-1" data-toggle="modal" data-target="#modalResepRiwayat">
                                            <i class="icon-plus2 mr-1 align-middle"></i>
                                            <span class="align-middle">Pilih dari Riwayat</span>
                                        </a>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                  <div class="wrap_line">
                    <h3> <span>Hasil Pengkajian Dietisien</span></h3>
                    <div class="row">
                      <!--
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Diagnosis Gizi<small class="txt_mandatory">*</small></label>
                          <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_diagnosis_gizi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                          <VValidate 
                              name="Diagnosis Gizi" 
                              v-model="row.apg_diagnosis_gizi" 
                              :rules="toValidate(mrValidation.apg_diagnosis_gizi)"
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Intervensi Gizi<small class="txt_mandatory">*</small></label>
                          <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_intervensi_gizi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                          <VValidate 
                              name="Intervensi Gizi" 
                              v-model="row.apg_intervensi_gizi" 
                              :rules="toValidate(mrValidation.apg_intervensi_gizi)"
                          />
                        </div>
                      </div>
                      -->

                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Monitoring dan Evaluasi<small class="txt_mandatory">*</small></label>
                          <b-form-textarea :formatter="$parent.normalText" v-model="row['apg_monitoring']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                          <VValidate 
                              name="Monitoring dan Evaluasi" 
                              v-model="row.apg_monitoring" 
                              :rules="toValidate(mrValidation.apg_monitoring)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            
            </div>
            <div class="card border shadow-0 mt-3" v-if="row.isEdit">
              <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
              </div>
              <div class="card-body">
                  <div class="form-group">
                  <label for="addInfoTindakan">Keterangan Perubahan</label>
                  <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                  </b-textarea>

                      
                  <VValidate 
                      :name="`Keterangan Perubahan`" 
                      v-model="row.arm_notes" 
                      :rules="{required: 1, min: 2, max:512}"
                  />
                  </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
      </b-form>
      </validation-observer>
    </div>
</template>
<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

// import GlobalVue from '@/libs/Global.vue'

export default{
    // extends: GlobalVue,
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        
        dataGiziTindakan: Array,
        dataGiziDiagnosa: Array,
        riwayatGiziTindakan: Array,
        riwayatGiziDiagnosa: Array,
        mGiziTindakan: Array,
        mGiziDiagnosa: Array,
    },
    data(){
        return {
            
            openriwayatGiziDiagnosa : false,
            openriwayatGiziTindakan : false,
            selectedGiziTindakan: '',
            selectedGiziDiagnosa: '',
            searchGiziDiagnosa: '',
            searchGiziTindakan: '',
            contentLen: 0
        }
    },
    methods: {
        countLen(){
            setTimeout(()=>{
                this.contentLen = $('.sp-col ul li').length
            },100)
        }, 
        hitungBBI(){
            if(this.row.ap_usia < 1){
              this.row.apg_bbi = (this.row.ap_usia + 9) / 2
            }else if(this.row.ap_usia >= 1 && this.row.ap_usia <= 6){
              this.row.apg_bbi = (this.row.ap_usia * 2) + 8
            }else if(this.row.ap_usia >= 7 && this.row.ap_usia <= 12){
              this.row.apg_bbi = (this.row.ap_usia * 7 - 5) / 2
            }else if(this.row.ap_gender == 1 && this.row.ap_usia > 12){
              this.row.apg_bbi = (this.row.apg_tb - 100) - ((this.row.apg_tb - 100)*(10/100))              
            }else if(this.row.ap_gender !== 1 && this.row.ap_usia > 12){
              this.row.apg_bbi = (this.row.apg_tb - 100) - ((this.row.apg_tb - 100)*(15/100))              
            }
            this.row.apg_bbi = this.row.apg_bbi.toFixed(2) 
        },
        hitungIMT(){
            if(this.row.apg_bb && this.row.apg_tb){
                this.row.apg_imt = this.row.apg_bb/this.row.apg_tb
                this.row.apg_imt = this.row.apg_imt.toFixed(2) 
            }
        },
        doSubmitDokterGizi(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   
            
            this.$refs['VFormDokterGizi'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                         setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }

                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data-gizi'
                            data.dataGiziDiagnosa = this.dataGiziDiagnosa
                            data.dataGiziTindakan = this.dataGiziTindakan

                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                      if(this.$parent.user.levelId == 1){
                                          this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uDokterGizi : null } }).catch(()=>{})
                                      }else{
                                          this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{})
                                      }   
                                      
                                      let dataSocket = {
                                        to : this.$parent.uDokterGizi,
                                        from : this.$parent.uDokterGizi,
                                        no_antrian: this.rowReg.ar_no_antrian_ppa_dietisien
                                      }
                                      this.$socket.emit('done_penunjang', dataSocket)
                                      
                                      let dataSocketAll = {
                                        to : 'ALLPJ',
                                        from : this.$parent.uDokterGizi,
                                        no_antrian: this.rowReg.ar_no_antrian_ppa_dietisien
                                      }
                                      this.$socket.emit('done_penunjang', dataSocketAll)
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save-gizi'
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        toValidate(val){
            return {...val}
        },
        back(){
          this.$router.back()
        },
        hitungKebutuhan(k){
            if(this.row.apg_zat_gizi[k].asupan && this.row.apg_zat_gizi[k].kebutuhan){
                this.row.apg_zat_gizi[k].kebutuhan_val = this.row.apg_zat_gizi[k].asupan / this.row.apg_zat_gizi[k].kebutuhan
                this.row.apg_zat_gizi[k].kebutuhan_val = this.row.apg_zat_gizi[k].kebutuhan_val.toFixed(2) 
            }
        },
         resetData(){
          this.searchGiziDiagnosa = '',
          this.searchGiziTindakan = '',
          this.openFormRacikan = false          
          this.$parent.apiGet()          
        },
        searchGiziDiagnosaData: _.debounce(function () {
            let data = {
                search: this.searchGiziDiagnosa,
                ar_ap_id : this.rowReg.ar_ap_id,
                arm_id : this.row.arm_id,
                type: 'search-gizi-diagnosa'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.$parent.riwayatGiziDiagnosa = resp.data
            })
        },500),
        searchGiziTindakanData: _.debounce(function () {
            let data = {
                search: this.searchGiziTindakan,
                ar_ap_id : this.rowReg.ar_ap_id,
                arm_id : this.row.arm_id,
                type: 'search-gizi-tindakan'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.$parent.riwayatGiziTindakan = resp.data
            })
        },500),
        saveGiziDiagnosa(){
          if(this.selectedGiziDiagnosa){
            let data = {
                id : this.selectedGiziDiagnosa,
                arm_id : this.row.arm_id,
                type: 'save-riwayat-gizi-diagnosa'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
              this.openriwayatGiziDiagnosa = false
              this.resetData()
            })
          }
        },
        saveGiziTindakan(){
          if(this.selectedGiziTindakan){
            let data = {
                id : this.selectedGiziTindakan,
                arm_id : this.row.arm_id,
                type: 'save-riwayat-gizi-tindakan'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
              this.openriwayatGiziTindakan = false
              this.resetData()
            })
          }
        },

        selectGiziTindakan(e,k){
          let index = this.mGiziTindakan.findIndex(x => x.value == e)
          if(index !== -1){
            this.dataGiziTindakan[k].apgt_tindakan = this.mGiziTindakan[index]['description']
          }
        },
        selectGiziDiagnosa(e,k){
          let index = this.mGiziDiagnosa.findIndex(x => x.value == e)
          if(index !== -1){
            this.dataGiziDiagnosa[k].apgd_diagnosa = this.mGiziDiagnosa[index]['description']
          }
        },
        
        getmGiziTindakan(diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.apgt_tindakan_id
            })
            return _.filter(this.mGiziTindakan, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },
        
        getmGiziDiagnosa(diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.apgd_diagnosa_id
            })
            return _.filter(this.mGiziDiagnosa, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },

         addNewGiziTindakan(){
          if(!this.row.isEdit){
            let data = {
              apgt_arm_id: this.row.arm_id,
              type: 'add-gizi-tindakan'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.dataGiziTindakan.push(resp.row)
            })
          }else{
            let data = {
              apgt_arm_id: this.row.arm_id,
              apgt_id: null
            }
            this.dataGiziTindakan.push(data)
          }
        },
        removedataGiziTindakan(id,k){
          if(!this.row.isEdit){
            let data = {
                id: id,
                type: 'remove-gizi-tindakan'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
                this.dataGiziTindakan.splice(k,1)
            })
          }else{
            this.dataGiziTindakan.splice(k,1)            
          }
        },
        
        addNewGiziDiagnosa(){
          if(!this.row.isEdit){
            let data = {
              apgd_arm_id: this.row.arm_id,
              type: 'add-gizi-diagnosa'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.dataGiziDiagnosa.push(resp.row)
            })
          }else{
            let data = {
              apgd_arm_id: this.row.arm_id,
              apgd_id: null
            }
            this.dataGiziDiagnosa.push(data)
          }
        },
        removedataGiziDiagnosa(id,k){
          if(!this.row.isEdit){
            let data = {
                id: id,
                type: 'remove-gizi-diagnosa'
            }
            Gen.apiRest(
                "/do/"+'RekamMedis',
                {data:data}, 
                "POST"
            ).then(()=>{
                this.dataGiziDiagnosa.splice(k,1)
            })
          }else{
            this.dataGiziDiagnosa.splice(k,1)            
          }
        },
        
        autoSaveGiziTindakan: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-gizi-tindakan',
                data : data
            }  
            if(!this.row.isEdit){
              Gen.apiRest(
                  "/do/"+'RekamMedis',
                  {data:dataPost}, 
                  "POST"
              )
            }
        },1000),  
        autoSaveGiziDiagnosa: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-gizi-diagnosa',
                data : data
            }  
            if(!this.row.isEdit){
              Gen.apiRest(
                  "/do/"+'RekamMedis',
                  {data:dataPost}, 
                  "POST"
              )
            }
        },1000),
        
        hitungBBU(){
          if(this.row.apg_bb){
            this.row.apg_bbu = this.row.apg_bb/this.row.ap_usia
            this.row.apg_bbu = this.row.apg_bbu.toFixed(2) 
          }
        },
        
        hitungTBU(){
          if(this.row.apg_tb){
            this.row.apg_tbu = this.row.apg_tb/this.row.ap_usia
            this.row.apg_tbu = this.row.apg_tbu.toFixed(2) 
          }
        },
        
        hitungBBTB(){
          if(this.row.apg_bb && this.row.apg_tb){
            this.row.apg_bbtb = this.row.apg_bb/this.row.apg_tb
            this.row.apg_bbtb = this.row.apg_bbtb.toFixed(2) 
          }
        },
        
        hitungLLAU(){
          if(this.row.apg_lla){
            this.row.apg_llau = this.row.apg_lla/this.row.ap_usia
            this.row.apg_llau = this.row.apg_llau.toFixed(2) 
          }
        },
    },
    mounted() {
      document.body.classList.add('sidebar-xs')
      setTimeout(()=>{
          this.row.arm_notes = null
          this.hitungBBI()
          this.hitungIMT()

          this.hitungBBU()
          this.hitungTBU()
          this.hitungBBTB()
          this.hitungLLAU()
      },2000)
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        dataGiziTindakan: {
          handler(v) {
              this.autoSaveGiziTindakan(v)
          },
          deep: true
        },
        dataGiziDiagnosa: {
          handler(v) {
              this.autoSaveGiziDiagnosa(v)
          },
          deep: true
      },
    }
}

</script>