<template>
    <div class="content">

        <validation-observer ref="VFormDokterTerapi">
        <b-form @submit.prevent="doSubmitDokterTerapi">
        <div class="card">
            <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Pengkajian Fisioterapi</h6>
            </div>
            <div class="card-body p-3">
            <div class="row">
                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Anamnesa</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div>
                            <div>
                            <div >
                                <b-form-radio-group
                                    :options="Config.mr.configAnamnesa"
                                    v-model="row['apdf_anamnesa']"
                                />
                                <VValidate 
                                    name="Anamnesa" 
                                    v-model="row.apdf_anamnesa" 
                                    :rules="toValidate(mrValidation.apdf_anamnesa)"
                                />
                            </div>
                            <div v-if="row.apdf_anamnesa == 2" >
                                <div class="ml-2 mt-2">
                                <b-form-input v-model="row.apdf_anamnesa_lainnya" type="text" name="perKeluhan" id="perKeluhan" class="form-control"
                                    placeholder="Hubungan" />
                                </div>
                                
                                <VValidate 
                                    name="Anamnesa Lainnya" 
                                    v-model="row.apdf_anamnesa_lainnya" 
                                    :rules="toValidate(mrValidation.apdf_anamnesa_lainnya)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div class="row mt-3">
                        
                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Penyakit Sekarang <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_penyakit_sekarang']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Penyakit Sekarang" 
                                v-model="row.apdf_riwayat_penyakit_sekarang" 
                                :rules="toValidate(mrValidation.apdf_riwayat_penyakit_sekarang)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Diagnosis Klinis <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_alergi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Diagnosis Klinis" 
                                v-model="row.apdf_riwayat_alergi" 
                                :rules="toValidate(mrValidation.apdf_riwayat_alergi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Penyakit Dahulu <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_penyakit_dahulu']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Penyakit Dahulu" 
                                v-model="row.apdf_riwayat_penyakit_dahulu" 
                                :rules="toValidate(mrValidation.apdf_riwayat_penyakit_dahulu)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Pengobatan <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_pengobatan']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Pengobatan" 
                                v-model="row.apdf_riwayat_pengobatan" 
                                :rules="toValidate(mrValidation.apdf_riwayat_pengobatan)"
                            />
                        </div>
                        </div>


                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Penyakit Keluarga <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_penyakit_keluarga']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Penyakit Keluarga" 
                                v-model="row.apdf_riwayat_penyakit_keluarga" 
                                :rules="toValidate(mrValidation.apdf_riwayat_penyakit_keluarga)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Riwayat Pekerjaan, Sosial Ekonomi, Kejiawaan dan Kebiasaan <strong
                                class="text-danger">*</strong></label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_riwayat_pekerjaan']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Riwayat Pekerjaan, Sosial Ekonomi, Kejiawaan dan Kebiasaan" 
                                v-model="row.apdf_riwayat_pekerjaan" 
                                :rules="toValidate(mrValidation.apdf_riwayat_pekerjaan)"
                            />
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Gizi</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div>
                            <div>
                            <div >
                                <b-form-radio-group
                                    :options="Config.mr.statusGizi"
                                    v-model="row['apdf_status_gizi']"
                                />
                                <VValidate 
                                    name="Status Gizi" 
                                    v-model="row.apdf_status_gizi" 
                                    :rules="toValidate(mrValidation.apdf_status_gizi)"
                                />
                                <div class="ml-2 mt-2" v-if="row.apdf_status_gizi == 'L'" >
                                    <b-form-input v-model="row.apdf_status_gizi_lainnya" type="text" name="perKeluhan" id="perKeluhan" class="form-control"
                                    placeholder="Sebutkan" />
                                    
                                    <VValidate 
                                        name="Gizi Lainnya" 
                                        v-model="row.apdf_status_gizi_lainnya" 
                                        :rules="toValidate(mrValidation.apdf_status_gizi_lainnya)"
                                    />
                                </div>
                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik (Termasuk pemeriksan Neurologis dan
                        Muskoloskeletal)</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                            <div class="form-group">
                                <label>Keluhan Utama <small class="txt_mandatory">*</small></label>

                                <b-form-textarea v-model="row.apdf_keluhan" :formatter="$parent.normalText"
                                name="perRiwayat" id="perRiwayat" rows="4" class="form-control"
                                placeholder="Keluhan Utama dari pasien">
                                </b-form-textarea>

                                <VValidate name="Keluhan Utama" v-model="row.apdf_keluhan"
                                :rules="toValidate(mrValidation.apdf_keluhan)" />
                            </div>
                            </div>
                            <div class="col-md-12">
                            <div class="wrap_anatomy_info">
                                <label for="perKeluhan">Lokasi Nyeri<strong class="text-danger">*</strong></label>
                                
                                <div class="position-relative" id="anatomiNyeri" @click.stop.prevent>
                                <div class="row">
                                    <div class="col-md-6">
                                    <div class="body_wrapper">
                                        <img :src="$parent.assetLocal('img/front-body.png')" class="img-fluid" alt="">
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                    <div class="body_wrapper">
                                        <img :src="$parent.assetLocal('img/back-body.png')" class="img-fluid" alt="">
                                    </div>
                                    </div>
                                </div>
                                <div class="anatomy-info" v-for="(v,k) in row.apdf_lokasi_nyeri||[]" :key="k"
                                    :style="`top:${v.y}%;left:${v.x}%`">
                                    <a href="javascript:;" @click="openAnat(k)" class="ai-point" v-b-tooltip.hover :html="true"
                                    :title="v.value"></a>
                                    <div :class="v.isOpen?'anatomy-info-input d-block':'anatomy-info-input'" @click.stop.prevent>
                                    <textarea v-model="v.value" name="anatomyInput" id="anatomyInput" rows="2"
                                        class="form-control form-control-sm"></textarea>
                                    <div class="mt-2 text-right">
            
                                        <a href="javascript:;" class="btn btn-sm btn-danger" @click.prevent.stop="removeAnat(k)">Hapus</a>
                                        <a href="javascript:;" @click.prevent.stop="closeAnat(k)" class="btn btn-sm btn-success">Simpan</a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="form-group mb-3 mt-3">
                                <label for="perKeluhan">Apakah nyerinya berpindah dari satu ke tempat lain<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                <b-form-radio-group
                                    :options="Config.mr.configNilaiNyeri"
                                    v-model="row['apdf_nilai_nyeri']"
                                />
                                <VValidate 
                                    name="Rasa Nyeri Pindah" 
                                    v-model="row.apdf_nilai_nyeri" 
                                    :rules="toValidate(mrValidation.apdf_nilai_nyeri)"
                                />
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label for="perKeluhan">Berapa Lama nyeri dirasakan<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                <b-form-radio-group
                                    :options="Config.mr.configLamaNyeri"
                                    v-model="row['apdf_lama_nyeri']"
                                />
                                <VValidate 
                                    name="Lama Nyeri" 
                                    v-model="row.apdf_lama_nyeri" 
                                    :rules="toValidate(mrValidation.apdf_lama_nyeri)"
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">

                        <div class="form-group mb-3 mt-3">
                            <label for="perKeluhan">Rasa Nyeri<strong class="text-danger">*</strong></label>
                            <div class="row">
                                <b-form-radio-group
                                    class="col-md-5 custom-block"
                                    :options="Config.mr.statusNyeri"
                                    v-model="row['apdf_rasa_nyeri']"
                                />
                                <VValidate 
                                    name="Rasa Nyeri" 
                                    v-model="row.apdf_rasa_nyeri" 
                                    :rules="toValidate(mrValidation.apdf_rasa_nyeri)"
                                />
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="perKeluhan">Apakah yang membuat nyeri berkurang atau bertambah?<strong
                                class="text-danger">*</strong></label>
                            <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                    <th colspan="2" class="table-light">Kompres:</th>
                                    </tr>
                                    <tr>
                                    <td v-for="v,k in Config.mr.kompres||[]" :key="k+'kompress'" >
                                        <div class="form-check mb-2  form-check-inline align-middle">
                                            <b-form-radio v-model="row.apdf_kompres" name="kompress" :value="v.value">{{v.text}}</b-form-radio>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <th colspan="2" class="table-light">Aktifitas:</th>
                                    </tr>
                                    <tr>
                                    <td v-for="v,k in Config.mr.aktifitas||[]" :key="k+'aktifitas'" >
                                        <div class="form-check mb-2  form-check-inline align-middle">
                                            <b-form-radio v-model="row.apdf_aktifitas" name="aktifitas" :value="v.value">{{v.text}}</b-form-radio>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <VValidate 
                                    name="Kompres" 
                                    v-model="row.apdf_kompres" 
                                    :rules="toValidate(mrValidation.apdf_kompres)"
                                />
                                <VValidate 
                                    name="Aktivitas" 
                                    v-model="row.apdf_aktifitas" 
                                    :rules="toValidate(mrValidation.apdf_aktifitas)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                <div class="card border shadow-0">
                    <div class="bg-light card-header py-2">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                        <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                        <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                        </a>
                    </div>
                    </div>
                    <div class="card-body">
                    <div class="form-group mb-0">
                        <div class="input-group">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                v-model="inputICD10"
                                @search="filterICD10($event,0)"
                                @input="selectICD10(inputICD10)"
                                :options="mICD10" label="text"
                                :clearable="true" :reduce="v=>v.value">
                                
                                <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="inputICD10Name">
                                    {{inputICD10Name||"-"}}
                                </span>
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                </template>
                                <template #list-footer v-if="(mICD10||[]).length >= 1">
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mICD10[0].currentPage}} dari
                                        {{ceilData(mICD10[0].totalRows/5)}}</small>
                                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                                </template>
                            </v-select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                    <thead>
                        <tr>
                        <th>Item ICD-10 dipilih</th>
                        <th width="64">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                        <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                        <td>
                            <a href="javascript:;" @click="removedataICD10(v.api10_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                            </a>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <div class="card-body py-2" v-else>
                    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                    </div>
                    <div class="card-body pt-2">
                    <div class="form-group mb-0">
                        <label for="inputManualDiagnose">
                        Diagnosa Lainnya <strong class="txt_mandatory">*</strong>
                        <span style="cursor:help;" data-popup="tooltip" title="Silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                        </label>
                        <b-textarea v-model="row.apdf_diagnosa" name=""
                        id="" rows="3" class="form-control"></b-textarea>
                        
                    <VValidate :name="`Diagnosa`" v-model="row.apdf_diagnosa"
                        :rules="{required:1, min:2, max:512}" />
                    </div>
                    </div>
                </div>
                </div>
    
                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Daftar masalah kedokteran fisik dan rehabilitas</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Mobilisasi  </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_mobilisasi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Mobilisasi" 
                                v-model="row.apdf_mobilisasi" 
                                :rules="toValidate(mrValidation.apdf_mobilisasi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">ADL</label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_adl']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="ADL" 
                                v-model="row.apdf_adl" 
                                :rules="toValidate(mrValidation.apdf_adl)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Komunikasi </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_komunikasi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Komunikasi " 
                                v-model="row.apdf_komunikasi" 
                                :rules="toValidate(mrValidation.apdf_komunikasi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Psikologi </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_psikologi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Psikologi " 
                                v-model="row.apdf_psikologi" 
                                :rules="toValidate(mrValidation.apdf_psikologi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Sosial Ekonomi </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_sosial_ekonomi']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Sosial Ekonomi " 
                                v-model="row.apdf_sosial_ekonomi" 
                                :rules="toValidate(mrValidation.apdf_sosial_ekonomi)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Vokasional </label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_vokasional']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Vokasional " 
                                v-model="row.apdf_vokasional" 
                                :rules="toValidate(mrValidation.apdf_vokasional)"
                            />
                        </div>
                        </div>

                        <div class="col-md-6">
                        <div class="form-group">
                            <label for="perKeluhan">Lain-lain</label>
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_lainnya']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Lain-lain" 
                                v-model="row.apdf_lainnya" 
                                :rules="toValidate(mrValidation.apdf_lainnya)"
                            />
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Kedokteran fisik dan rehabilitasi</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div class="form-group">
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_rencana_kedokteran']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Rencana Kedokteran fisik dan rehabilitasi" 
                                v-model="row.apdf_rencana_kedokteran" 
                                :rules="toValidate(mrValidation.apdf_rencana_kedokteran)"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana pemeriksaan penunjang</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div class="form-group">
                            <b-form-textarea :formatter="$parent.normalText" v-model="row['apdf_rencana_pemerikasaan_penunjang']" rows="5" cols="3" class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                            <VValidate 
                                name="Rencana pemeriksaan penunjang"
                                v-model="row.apdf_rencana_pemerikasaan_penunjang" 
                                :rules="toValidate(mrValidation.apdf_rencana_pemerikasaan_penunjang)"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                <div class="col-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Dirujuk</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row gx-5">
                        <div class="col-md-12">
                        <div>
                            <div >
                                <b-form-radio-group
                                    :options="Config.mr.statusDirujuk"
                                    v-model="row['apdf_dirujuk']"
                                />
                                <VValidate 
                                    name="Status Gizi" 
                                    v-model="row.apdf_dirujuk" 
                                    :rules="toValidate(mrValidation.apdf_dirujuk)"
                                />
                                <div class="ml-2 mt-2" v-if="row.apdf_dirujuk == 'L'" >
                                    <b-form-input v-model="row.apdf_dirujuk_lainnya" type="text" name="perKeluhan" id="perKeluhan" class="form-control"
                                    placeholder="Sebutkan" />
                                    
                                    <VValidate 
                                        name="Gizi Lainnya" 
                                        v-model="row.apdf_dirujuk_lainnya" 
                                        :rules="toValidate(mrValidation.apdf_dirujuk_lainnya)"
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>

                <div class="col-md-12 mb-2">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                    </div>
                    <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-4">
                        <div class="wrap_line">
                            <h3>Elektroterapi</h3>
                            <div class="form-row">
                                <b-form-checkbox-group 
                                    v-model="row.apdf_rencana_tindakan_elektroterapi"
                                    name="Elektroterapi"
                                    :options="statusElektroterapi"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-4">
                        <div class="wrap_line">
                            <h3>Mekanoterapi</h3>
                            <div class="form-row">
                                <b-form-checkbox-group 
                                    v-model="row.apdf_rencana_tindakan_mekanoterapi"
                                    name="Mekanoterapi"
                                    :options="statusMekanoTerapi"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-4">
                        <div class="wrap_line">
                            <h3>Latihan - latihan</h3>
                            <div class="form-row">
                                <b-form-checkbox-group 
                                    v-model="row.apdf_rencana_tindakan_latihan"
                                    name="Latihan - latihan"
                                    :options="statusLatihan"
                                    class="checkbox-block"
                                ></b-form-checkbox-group>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <div class="col-md-12">
                <div class="card border shadow-0">
                    <div class="bg-light card-header py-2">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                        <a href="javascript:;" v-if="riwayatICD9.length" @click="openriwayatICD9 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                        <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                        </a>
                    </div>
                    </div>
                    <div class="card-body">
                    <div class="form-group mb-0">
                        <div class="input-group">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                v-model="inputICD9"
                                @search="filterICD9($event,0)"
                                @input="selectICD9(inputICD9)"
                                :options="mICD9" label="text"
                                :clearable="true" :reduce="v=>v.value">
                                
                                <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="inputICD9Name">
                                    {{inputICD9Name||"-"}}
                                </span>
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                </template>
                                <template #list-footer v-if="(mICD9||[]).length >= 1">
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mICD9[0].currentPage}} dari
                                        {{ceilData(mICD9[0].totalRows/5)}}</small>
                                    <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                                </template>
                            </v-select>
                        </div>
                        </div>
                    </div>
                    </div>
                    <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                    <thead>
                        <tr>
                        <th>Item ICD-9 dipilih</th>
                        <th width="64">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                        <td><span>{{v.mi9_name||"-"}}<span class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                        <td>
                            <a href="javascript:;" @click="removedataICD9(v.api9_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                            <i class="icon-trash-alt"></i>
                            </a>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <div class="card-body py-2" v-else>
                    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                    </div>
                    <div class="card-body pt-2">
                    <div class="form-group mb-0">
                        <label for="inputManualDiagnose">
                        Tindakan Lainnya <strong class="txt_mandatory">*</strong>
                        <span style="cursor:help;" data-popup="tooltip" title="Silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                        </label>
                        <b-textarea v-model="row.apdf_tindakan" name=""
                        id="" rows="3" class="form-control"></b-textarea>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            
            <div class="card border shadow-0"> 
                <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Tindak Lanjut Rujukan</h6>
                </div>
                <div class="card-body mt-3">
                    <div class="row g-3 pl-2">
                    <div class="col-md-12">
                        <b-form-group>
                        <label>Apakah Pasien akan dirujuk?<span
                            class="text-danger mr5">*</span></label>
                        <b-form-radio-group :options="Config.mr.yesNoOptV2"
                            v-model="rowReg.ar_is_rujukan" />
                        </b-form-group>
                    </div>
                    </div>

                    <template v-if="rowReg.ar_is_rujukan == 'Y'">
                    <div class="row">
                        <div class="col-12">
                        <label for=""><strong>Rujuk Pasien</strong></label>
                        <b-form-radio-group class="mt-1" :options="Config.mr.typeRujukanV2"
                            v-model="row.artlr_type" />
                        <VValidate :name="'Rujuk Pasien'"
                            v-model="row.artlr_type"
                            :rules="{required : 1}" />
                        </div>
                    </div>
                    <div class="row mt-3" v-if="row.artlr_type == 'RI'" id="formRujukInternal">
                        <div class="col-md-4">
                        <div class="form-group">
                            <label for="poliSelection">Pilih Poli</label>

                            <v-select placeholder="Pilih Poli"
                            v-model="row.artlr_poli" @input="selectDokter($event)"
                            :options="mPoli" label="text" :clearable="true"
                            :reduce="v=>v.value"></v-select>
                            
                            <VValidate :name="'Poli'"
                            v-model="row.artlr_poli"
                            :rules="{required : 1}" />
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group">
                            <label for="docSelection">Pilih Dokter</label>
                            <v-select placeholder="Pilih Dokter" v-model="row.artlr_dokter"
                            :options="mDokter" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Dokter'"
                            v-model="row.artlr_dokter"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-8">
                        <div class="form-group">
                            <label> Catatan<small class="txt_mandatory">*</small></label>
                            <textarea v-model="row.artlr_notes" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                            <VValidate :name="'Catatan'"
                            v-model="row.artlr_notes"
                            :rules="{required : 1, min: 2}" />
                        </div>
                        </div>
                    </div>
                    <div class="row mt-3" v-else-if="row.artlr_type == 'RK'" id="formRujukKasus">
                        <div class="col-md-3">
                        <div class="form-group">
                            <label>Alasan Merujuk<small class="txt_mandatory">*</small></label>
                            <v-select placeholder="Alasan Rujuk" v-model="row.artlr_alasan_rujuk"
                            :options="Config.mr.alasanRujuk" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Alasan Rujuk'"
                            v-model="row.artlr_alasan_rujuk"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-3">
                        <div class="form-group">
                            <label>{{getConfigDynamic(Config.mr.alasanRujuk, row.artlr_alasan_rujuk)||"-"}}<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            <b-form-input v-model="row.artlr_alasan_rujuk_text" type="text" class="form-control" />

                            <VValidate :name="'Alasan DiRujuk'"
                            v-model="row.artlr_alasan_rujuk_text"
                            :rules="{required : 1}" />
                        </div>
                        </div>

                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="poliSelection">Pilih Fakes Rujukan</label>

                            <v-select placeholder="Pilih Fakes Rujukan"
                            v-model="row.artlr_faskes_rujuk" @input="selectDokterRujukan($event)"
                            :options="mFaskes" label="text" :clearable="true"
                            :reduce="v=>v.value"></v-select>
                            
                            <VValidate :name="'Faskes'"
                            v-model="row.artlr_faskes_rujuk"
                            :rules="{required : 1}" />
                        </div>
                        </div>
                        <div class="col-md-3">
                        <div class="form-group">
                            <label for="docSelection">Pilih Dokter</label>
                            <v-select placeholder="Pilih Dokter" v-model="row.artlr_dokter_rujuk"
                            :options="mDokterRujukan" label="text" :clearable="true"
                            :reduce="v=>v.value">
                            </v-select>
                            <VValidate :name="'Dokter'"
                            v-model="row.artlr_dokter_rujuk"
                            :rules="{required : 1}" />
                        </div>
                        </div>
                        
                        <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                            <div class="form-row">
                                <div class="form-group col-md-5">
                                <label>Peralatan Media yang Terpasang<small class="txt_mandatory">*</small></label>
                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                    v-model="row.artlr_is_media" />
                                <VValidate :name="'Peralatan Media yang Terpasang'"
                                v-model="row.artlr_is_media"
                                :rules="{required : 1}" />
                                </div>
                                <div class="form-group col-md-4 mt-3" v-if="row.artlr_is_media == 'Y'">
                                <v-select placeholder="Pilih Media" v-model="row.artlr_media"
                                    :options="Config.mr.mediaTerpasang" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>
                                <VValidate :name="'Media'"
                                v-model="row.artlr_media"
                                :rules="{required : 1}" />
                                </div>
                            </div>

                            </div>

                        </div>
                        </div>
                        <div class="col-md-8">
                        <div class="form-group">
                            <label> Perawatan Pasien yang dibutuhkan<small class="txt_mandatory">*</small></label>
                            <textarea v-model="row.artlr_perawatan_pasien" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                            
                            <VValidate :name="'Perawatan Pasien yang dibutuhkan'"
                            v-model="row.artlr_perawatan_pasien"
                            :rules="{required: 1, min: 3, max: 128}" />
                        </div>
                        </div>

                        <div class="col-md-12">
                        <div class="form-row">
                            <div class="form-group col-md-3">
                            <label> Persetujuan Biaya RS Rujukan<small class="txt_mandatory">*</small></label>
                            <v-select placeholder="Pilih Persetujuan Biaya RS Rujukan" v-model="row.artlr_is_biaya"
                                :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                :reduce="v=>v.value">
                            </v-select>

                            <VValidate :name="'Persetujuan Biaya RS Rujukan'"
                            v-model="row.artlr_is_biaya"
                            :rules="{required : 1}" />
                            </div>

                            <div class="form-group col-md-3">
                            <label> Persetujuan Risiko Perjalanan <small class="txt_mandatory">*</small></label>
                            <v-select placeholder="Pilih Persetujuan Risiko Perjalanan" v-model="row.artlr_is_risiko"
                                :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                :reduce="v=>v.value">
                            </v-select>

                            <VValidate :name="'Persetujuan Risiko Perjalanan'"
                            v-model="row.artlr_is_risiko"
                            :rules="{required : 1}" />
                            </div>

                            <div class="form-group col-md-6">
                            <label> Kejadian klinis selama dilakukan transfer <small class="txt_mandatory">*</small></label>
                            <div class="form-row">
                                <div class="col-md-6">
                                <v-select placeholder="Pilih Persetujuan Risiko Perjalanan" v-model="row.artlr_is_kejadian_klinis"
                                    :options="Config.mr.yesNoOptV2" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>

                                <VValidate :name="'Persetujuan Risiko Perjalanan'"
                                v-model="row.artlr_is_kejadian_klinis"
                                :rules="{required : 1}" />

                                </div>
                                <div class="col-md-6" v-if="row.artlr_is_kejadian_klinis == 'Y'">
                                <input class="form-control" v-model="row.artlr_kejadian_klinis" placeholder="Tuliskan Kejadian Klinis">
                                
                                <VValidate :name="'Persetujuan Risiko Perjalanan'"
                                v-model="row.artlr_kejadian_klinis"
                                :rules="{required : 1}" />
                                </div>
                            </div>

                            </div>
                        </div>
                        </div>
                        <div class="col-md-6 mb-5">
                        <a href="template-rujukan-external.html" class="btn btn-labeled btn-labeled-left bg-orange-400">
                        <b><i class="icon-printer"></i></b>
                        Cetak Surat Rujuk Kasus
                        </a>
                        </div>
                    </div>
                    <div class="row mt-3" v-else id="formRujukBalik">
                        <div class="col-md-4">
                        <div class="form-group">
                            <label>Faskes Rujukan<small class="txt_mandatory">*</small></label>
                            <div class="clearfix"></div>
                            <input class="form-control" readonly placeholder="No Rujukan Faskes I">
                        </div>
                        </div>

                        <div class="col-md-4"></div>

                        <div class="col-md-8">
                        <div class="form-group">
                            <label> Catatan<small class="txt_mandatory">*</small></label>
                            <textarea v-model="row.artlr_notes" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                            <VValidate :name="'Catatan'"
                            v-model="row.artlr_notes"
                            :rules="{required : 1, min: 2}" />
                        </div>
                        </div>
                    </div>
                    </template>
                </div>
            </div>
            
            <div class="card border shadow-0 mt-3" v-if="row.isEdit">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                </div>
                <div class="card-body">
                    <div class="form-group">
                    <label for="addInfoTindakan">Keterangan Perubahan</label>
                    <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                    </b-textarea>

                        
                    <VValidate 
                        :name="`Keterangan Perubahan`" 
                        v-model="row.arm_notes" 
                        :rules="{required: 1, min: 2, max:512}"
                    />
                    </div>
                </div>
            </div>
            <div class="card-footer">
            <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
            </div>
        </div>
        </b-form>
        </validation-observer>
        
        <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
            <div class="modal-body p-0">
            <div class="mb-3">
                <div class="row">
                <div class="col-md-5 col-lg-4">
                    <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                        <input v-model="searchICD10" @input="searchICD10Data" class="form-control" placeholder="Ketik Nama Dokter / No. Registrasi" />
                        <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                        </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                    </div>
                </div>
                </div>
            </div>
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD10</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD10">
                    <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose" :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>
                    
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}} <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD10||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="modal-footer p-0 mt-3">
            <div class="text-right">
                <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
                <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10" class="btn btn-success btn-labeled btn-labeled-left"><b><i
                    class="icon-checkmark"></i></b>Simpan Pilihan</button>
            </div>
            </div>
        </b-modal>

        <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
            <div class="modal-body p-0">
            <div class="mb-3">
                <div class="row">
                <div class="col-md-5 col-lg-4">
                    <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                        <input v-model="searchICD9" @input="searchICD9Data" class="form-control" placeholder="Ketik Nama Dokter / No. Registrasi" />
                        <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                        </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                    </div>
                </div>
                </div>
            </div>
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD9</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD9">
                
                    <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose" :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>
                    
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}} <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD9||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="modal-footer p-0 mt-3">
            <div class="text-right">
                <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
                <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9" class="btn btn-success btn-labeled btn-labeled-left"><b><i
                    class="icon-checkmark"></i></b>Simpan Pilihan</button>
            </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

// import GlobalVue from '@/libs/Global.vue'

export default{
    // extends: GlobalVue,
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        statusElektroterapi: Array,
        statusMekanoTerapi: Array,
        statusLatihan: Array,
        
        dataICD9: Array,
        dataICD10: Array,
        riwayatICD9: Array,
        riwayatICD10: Array,
        mICD9: Array,
        mICD10: Array,
        
        mPoli: Array,
        mDokter: Array,
    
        mFaskes: Array,
        mDokterRujukan: Array,
    },
    data(){
        return {
            openriwayatICD10 : false,
            openriwayatICD9 : false,
            selectedICD9: '',
            selectedICD10: '',
            searchICD10: '',
            searchICD9: '',
    
            inputICD9: null,
            inputICD10: null,
            
            inputICD9Name: null,
            inputICD10Name: null,

        }
    },
    
    mounted() {
        
      document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.setPosisionNyeri()
            this.row.arm_notes = null
        },1500)
    },
    methods: {
        selectDokter(e) {
            let data = {
            id: e,
            type: 'get-by-poli'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.mDokter = res.data.mDokter
            })
        },
        
        selectDokterRujukan(e) {
            let data = {
            id: e,
            type: 'get-by-faskes'
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
             this.mDokterRujukan = res.data.mDokterRujukan
            })
        },

        ceilData(value){
          return Math.ceil(value)
        },
        resetData(){
          this.searchICD10 = '',
          this.searchICD9 = '',
          this.openFormRacikan = false          
          this.$parent.apiGet()          
        },
            
        selectICD9(e) {
            let index = this.mICD9.findIndex(x => x.value == e)
            if (index !== -1) {
            this.inputICD9Name =  this.mICD9[index]['text']
            }else{
            this.inputICD9Name =  null
            }
            this.addNewICD9()
        },
        selectICD10(e) {
            let index = this.mICD10.findIndex(x => x.value == e)
            if (index !== -1) {
            this.inputICD10Name =  this.mICD10[index]['text']
            }else{
            this.inputICD10Name =  null
            }
            this.addNewICD10()
        },

        filterICD10: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD10 || []).length; i++) {
            if (this.dataICD10[i]['api10_icd_10']) {
                id.push(this.dataICD10[i]['api10_icd_10'])
            }
            }
            Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
                type: 'select-icd-10',
                search: e,
                id: id
            }
            }, 'POST').then(res => {
            this.$parent.mICD10 = res.data.data
            })
        }, 10),

        changePageICD10(page, k, act, col) {
            if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
            } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
            }

            let data = {
            type: 'select-paging-icd-10',
            page: page,
            search: this[col][k]['search']
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.$parent.mICD10 = res.data.data
            this.getmICD10(this.dataICD10, k)
            })
        },

        filterICD9: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD9 || []).length; i++) {
            if (this.dataICD9[i]['api9_icd_9']) {
                id.push(this.dataICD9[i]['api9_icd_9'])
            }
            }
            Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
                type: 'select-icd-9',
                search: e,
                id: id
            }
            }, 'POST').then(res => {
            this.$parent.mICD9 = res.data.data
            })
        }, 10),

        changePageICD9(page, k, act, col) {
            if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
            } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
            }
            let data = {
            type: 'select-paging-icd-9',
            page: page,
            search: this[col][k]['search']
            }
            Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
            ).then(res => {
            this.$parent.mICD9 = res.data.data
            this.getmICD9(this.dataICD9, k)
            })
        },

        addNewICD9() {
            if(this.inputICD9){
            if (!this.row.isEdit) {
                let data = {
                api9_arm_id: this.row.arm_id,
                api9_icd_9: this.inputICD9,
                type: 'add-icd-9'
                }
                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
                ).then(res => {
                let resp = res.data
                this.dataICD9.push(resp.row)
                this.inputICD9 = null
                })
            } else {
                let data = {
                api9_arm_id: this.row.arm_id,
                api9_id: null
                }
                this.dataICD9.push(data)
            }
            }
        },
        removedataICD9(id, k) {
            if (!this.row.isEdit) {
            let data = {
                id: id,
                type: 'remove-icd-9'
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                data: data
                },
                "POST"
            ).then(() => {
                this.dataICD9.splice(k, 1)
            })
            } else {
            this.dataICD9.splice(k, 1)
            }
        },

        addNewICD10() {
            if(this.inputICD10){
            if (!this.row.isEdit) {
                let data = {
                api10_arm_id: this.row.arm_id,
                api10_icd_10: this.inputICD10,
                type: 'add-icd-10'
                }
                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: data
                },
                "POST"
                ).then(res => {
                let resp = res.data
                this.dataICD10.push(resp.row)
                this.inputICD10 = null
                })
            } else {
                let data = {
                api10_arm_id: this.row.arm_id,
                api10_id: null
                }
                this.dataICD10.push(data)
            }
            }
        },
        removedataICD10(id, k) {
            if (!this.row.isEdit) {
            let data = {
                id: id,
                type: 'remove-icd-10'
            }
            Gen.apiRest(
                "/do/" + 'RekamMedis', {
                data: data
                },
                "POST"
            ).then(() => {
                this.dataICD10.splice(k, 1)
            })
            } else {
            this.dataICD10.splice(k, 1)
            }
        },
        
        getmICD9(diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.api9_icd_9
            })
            return _.filter(this.mICD9, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },
        
        getmICD10(diagnosa,k){
            const sldMenu = _.map(_.filter(diagnosa||[], (v2, k2)=>{
                return k2!=k
            }), v2=>{
                return v2.api10_icd_10
            })
            return _.filter(this.mICD10, v2=>{
                return sldMenu.indexOf(v2.value)<=-1
            })
        },
        
        back(){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{}) 
        },
        setPosisionNyeri() {
            let posX = false
            let posY = false
            let self = this

            $("body").mousemove(function (e) {
                if (!$("body").hasClass("active")) $("body").addClass("active")
                if($("#anatomiNyeri").length){
                  posX = parseInt(e.pageX - ($("#anatomiNyeri").offset().left + 8))
                  posY = parseInt(e.pageY - ($("#anatomiNyeri").offset().top + 52))
                }
            })

            $('#anatomiNyeri').on('click', function (e) {
                e.stopPropagation()
                let index = self.row.apdf_lokasi_nyeri.findIndex(x => x.isOpen == true)
                if(index !== -1){
                  self.row.apdf_lokasi_nyeri.splice(index,1)
                }else{
                  self.row.apdf_lokasi_nyeri.push({
                      x: (posX / $(this).width())*100,
                      y: (posY / $(this).height())*100,
                      value: '',
                      isOpen: true,
                  })
                }
            })
        },
        removeAnat(k){
            this.row.apdf_lokasi_nyeri.splice(k,1)
        },
        closeAnat(k){
            this.row.apdf_lokasi_nyeri[k]['isOpen'] = false
            this.row.apdf_lokasi_nyeri[k]['y'] += (42 / $('#anatomiNyeri').height())*100
        },
        openAnat(k){
            this.row.apdf_lokasi_nyeri[k]['y'] -= (42 / $('#anatomiNyeri').height())*100
            this.row.apdf_lokasi_nyeri[k]['isOpen'] = true
        },
        doSubmitDokterTerapi(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   

            this.$refs['VFormDokterTerapi'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }


                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data-dokter-terapi'
                            data.dataICD9 = this.dataICD9
                            data.dataICD10 = this.dataICD10
                            data.ar_is_rujukan = this.rowReg.ar_is_rujukan
                            data.ar_rujukan_to = this.rowReg.ar_rujukan_to
                            data.ar_rujukan_poli_to = this.rowReg.ar_rujukan_poli_to

                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.$parent.user.levelId == 1){
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uDokterFisio : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{})
                                        }  
                                                            
                                        this.$socket.emit('refresh_data', {to : this.$parent.uRadiologi})
                                        this.$socket.emit('refresh_data', {to : this.$parent.uLab})
                                        this.$socket.emit('refresh_data', {to : this.$parent.uDokterGizi})
                                        this.$socket.emit('refresh_data', {to : this.$parent.uFisio})

                                        this.$socket.emit('refresh_data', {to : 'ALLPJ'})
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }
            })
        },
        
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save-dokter-terapi'
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        autoSaveICD9: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-icd-9',
                data : data
            }  
            if(!this.row.isEdit){
              Gen.apiRest(
                  "/do/"+'RekamMedis',
                  {data:dataPost}, 
                  "POST"
              )
            }
        },1000),  
        autoSaveICD10: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-icd-10',
                data : data
            }  
            if(!this.row.isEdit){
              Gen.apiRest(
                  "/do/"+'RekamMedis',
                  {data:dataPost}, 
                  "POST"
              )
            }
        },1000),
        toValidate(val){
            return {...val}
        },

    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        dataICD9: {
          handler(v) {
              this.autoSaveICD9(v)
          },
          deep: true
        },
        dataICD10: {
          handler(v) {
              this.autoSaveICD10(v)
          },
          deep: true
        },
    }
}

</script>
