<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.ar_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>

                        <div v-if="typeKajian=='kajian-dokter'||typeKajian=='kajian-dokter-terapi'||typeKajian=='kajian-gizi'" class="d-flex flex-column">
                            <a href="javascript:;" @click="viewKajianPerawat()" data-toggle="modal" class="btn btn-outline-light">Lihat Kajian Perawat</a>

                            <a href="javascript:;" v-if="typeKajian=='kajian-dokter' && row.kajianLatestDokter" @click="doOpenRiwayatDokter(row.kajianLatestDokter)" data-toggle="modal" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis</a>
                        </div>
                        
                        
                        <div v-else class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div v-if="isList" class="content">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <h5 class="card-title font-weight-semibold mr-auto">Rekam Medis No. <span class="text-blue-600">{{row.ap_code||"-"}}</span></h5>
                            <a href="javascript:;" v-if="user.levelId == uPerawat || user.levelId == uDokter || user.levelId == 1 || user.levelId == uDokterFisio" @click="openReport = true" class="btn alpha-green btn-sm border-green text-green-800 font-weight-semibold ml-2" data-toggle="modal" data-target="#modalSuratMenyurat">
                                <i class="icon-files-empty mr-1"></i>
                                Unduh Dokumen Pasien
                            </a>
                        </div>
                        
                        <template v-if="user.levelId !== uAdmin">
                        <a href="javascript:;" v-if="isShowBtn && rowReg.ar_is_penjunjang_fisio != 'Y'" @click="toKajianAwal()" class="btn bg-blue-600 btn-labeled btn-labeled-left ml-auto">
                            <b><i class="icon-plus2"></i></b>
                            <template v-if="rowReg.ar_status == sPPA">
                                <template v-if="user.levelId == uFisio">
                                    Buat Kajian Fisio Terapi
                                </template>
                                <template v-else-if="user.levelId == uLab">
                                    Buat Kajian Laboratorium
                                </template>
                                <template v-else-if="user.levelId == uRadiologi">
                                    Buat Kajian Radiologi
                                </template>
                                <template v-else-if="user.levelId == uDokterGizi">
                                    Buat Kajian Dietisien
                                </template>
                                <template v-else>
                                    Buat Kajian Awal
                                </template>
                            </template>
                            <template v-else>
                                Buat Kajian Awal
                            </template>
                        </a>
                        </template>

                    </div>
                </div>
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                    <thead>
                        <tr>
                            <th>No. Reg</th>
                            <th>Tanggal Reg</th>
                            <th>Nama Dokter</th>
                            <th>Keluhan</th>
                            <th>Kajian</th>
                            <th>Riwayat RM</th>
                            <th>Aksi</th>
                            <th>Hapus</th>
                        </tr>
                    </thead>
                    <template v-if="(dataList||[]).length">
                        <template v-for="(v,k) in (dataList||[])" >
                            <tbody :class="v.isHead? 'isHead': ''" :key="k">
                                <tr :class="v.arm_is_draft == 'Y' ? 'table-warning' : ''">
                                    <td>
                                        <a v-b-tooltip.hover v-if="(v.arm_can_edit_by && v.arm_is_approve == 'Y' || otherConditional(v) )" title="Edit Data" href="javascript:;" @click="redirect(v)" class="text-primary font-weight-semibold border-bottom">{{v.ar_reg_code||"-"}}</a>
                                        <span v-else>{{v.ar_reg_code||"-"}}</span>
                                        
                                        <template v-if="v.arm_is_draft == 'Y'">
                                            <span :class="`badge bg-warning ml-1`">
                                            Draft
                                            </span><br/>
                                            <small>{{v.petugas||"-"}}</small>
                                        </template>
                                    </td>
                                    <td>
                                        <span v-if="v.ar_reg_date">{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                                        <span v-else>{{ v.ar_reg_date | moment("DD MMM YYYY")}}</span>
                                    </td>
                                    <td>
                                        <strong>{{v.bu_full_name||"-"}}<small><br>{{v.mpo_name}}</small></strong>
                                    </td>

                                    <td>
                                        <span>{{v.keluhan||"-"}}</span>
                                    </td>
                                    
                                    <td>
                                    <a href="javascript:;" v-if="v.kajianPerawatId" @click="toDetail(v,v.arm_ar_id)" class="btn btn-sm btn-icon" :class="v.arm_is_kajian_awal == 'Y' ? 'alpha-blue border-blue' : 'alpha-green border-green'" v-b-tooltip.hover="'Lihat Kajian '+(v.arm_is_kajian_awal == 'Y' ? 'Awal' : 'Lanjutan')"><i class="icon-file-eye"></i></a>
                                    <span v-else>-</span>
                                    </td>
                                    
                                    
                                    <td><a href="javascript:;" v-if="(v.kajianDokter || v.kajianDokterTerapi || v.kajianGizi || v.kajianFisio || v.kajianLab || v.kajianRadiologi )" @click="doOpenRiwayatRM(v)" v-b-tooltip.hover="'Lihat Riwayat Rekam Medis'" class="btn btn-sm btn-icon alpha-orange border-orange"><i class="icon-file-eye"></i></a>
                                    <span v-else>-</span>
                                    </td>
                                    <td>
                                        <template v-if="rowReg.ar_status !== sDokter">                
                                            <a v-if="v.arm_is_draft !== 'Y' && isShowBtn && isShowBtnLanjutan && user.levelId !== uAdmin && rowReg.ar_mpo_id == v.arm_poli_id" @click="toKajianLanjut(v.arm_aka_id, v.arm_id)" href="javascript:;" class="btn bg-teal btn-labeled btn-labeled-left">
                                                <b><i class="icon-plus2"></i></b>
                                                <template v-if="rowReg.ar_status == sPPA">
                                                    <template v-if="user.levelId == uFisio">
                                                        Buat Kajian Fisio Terapi
                                                    </template>
                                                    <template v-else-if="user.levelId == uLab">
                                                        Buat Kajian Laboratorium
                                                    </template>
                                                    <template v-else-if="user.levelId == uRadiologi">
                                                        Buat Kajian Radiologi
                                                    </template>
                                                    <template v-else>
                                                        Buat Kajian Dietisien
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    Buat Kajian Lanjutan
                                                </template>
                                            </a>
                                            <span v-else> - </span>
                                        </template>
                                        
                                        <template v-else>

                                            <a v-if="v.arm_is_draft !== 'Y' && isShowBtn && isShowBtnLanjutan && user.levelId !== uAdmin && rowReg.ar_mpo_id == v.arm_poli_id" @click="toKajianLanjut(v.arm_aka_id, v.arm_id)" href="javascript:;" class="btn bg-teal btn-labeled btn-labeled-left">
                                                <b><i class="icon-plus2"></i></b>
                                                Buat Kajian Lanjutan
                                            </a>
                                            <span v-else> - </span>
                                        </template>
                                    </td>
                                    
                                    <td>
                                        <a v-if="v.arm_is_draft == 'Y' && (v.arm_can_edit_by && v.arm_is_approve == 'Y' || otherConditional(v) )" href="javascript:;" @click="deleteRiwayatRM(v)" v-b-tooltip.hover="'Hapus Data Rekam Medis'" class="btn btn-sm btn-icon alpha-danger border-danger"><i class="icon-bin"></i></a>
                                        <span v-else> - </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </template>
                    <tbody v-else-if="!(dataList||[]).length && dataList">
                        <tr>
                            <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                Not Found Data
                            </th>
                        </tr>      
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th colspan="99" class="table-info text-blue-700 text-uppercase font-weight-semibold">
                                <div class="skeletal-comp mb-2"></div>
                                <div class="skeletal-comp mb-2"></div>
                                <div class="skeletal-comp"></div>
                            </th>
                        </tr>
                    </tbody>
                    <tfoot v-if="(dataList||[]).length && pageNow < last_page">
                        <tr>
                            <td class="text-center" colspan="99">
                                <a href="javascript:;" @click="loadMore" class="btn btn-outline-primary btn-sm font-weight-semibold">Tampilkan Rekam Medis Lainnya</a>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
            <div class="card-footer">
                <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                </div>
            </div>
            <!-- /dashboard content -->
            
            <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
                <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
                <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
            </b-modal>
            
        </div>
        <div v-else>
            <FormKajianAwal v-bind="passToSub" v-if="typeKajian == 'awal'" />
            <FormKajianLanjutan v-bind="passToSubLanjutan" v-if="typeKajian == 'lanjutan'" />
            <FormKajianDokter v-bind="passToSubDokter" v-if="typeKajian == 'kajian-dokter'" />
            <FormKajianDokterTerapi v-bind="passToSubDokterTerapi" v-if="typeKajian == 'kajian-dokter-terapi'" />
            <FormKajianGizi v-bind="passToSubGizi" v-if="typeKajian == 'kajian-gizi'" />

            <FormKajianFisio v-bind="passToSubPPAFisio" v-if="typeKajian == 'kajian-awal-fisio'" />
            <FormKajianFisioLanjutan v-bind="passToSubPPAFisioLanjutan" v-if="typeKajian == 'kajian-lanjutan-fisio'" />
            <FormKajianRadiologi v-bind="passToSubRadiologi" v-if="typeKajian == 'kajian-radiologi'" />
            <FormKajianLab v-bind="passToSubLab" v-if="typeKajian == 'kajian-lab'" />
            
            <b-modal v-model="openDetailKajianPerawat" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
                <DetailKajianAwal v-bind="dataRiwyatRMPerawat" v-if="dataRiwyatRMPerawatIS == 'Y'"/>
                <DetailKajianLanjutan v-bind="dataRiwyatRMPerawat" v-else />
            </b-modal>
                    
        </div>        
            
        <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
            <RiwayatRM v-bind="passToSubRM" />
        </b-modal>

        
        <b-modal v-model="openReport" :title="'Unduh Dokumen'" size="lg" hide-footer>
            <div class="row row-gutter">
                <div class="col-md-4">
                    <div class="wrap_line mb-0">
                    <h3 class="pb-1">Surat Keterangan Sakit</h3>
                    <a href="javascript:;" @click="downloadReport('suratKeteranganSakit','Surat Keterangan Sakit')" class="btn btn-success btn-labeled btn-labeled-left">
                        <b><i class="icon-download"></i></b>
                        <span>Unduh</span>
                    </a>
                    </div>
                </div>
                
                <template v-if="rowReg.ar_is_rujukan == 'Y' && Object.keys(row.kajianDokterData||{}).length">
                    <div class="col-md-4" v-if="row.kajianDokterData.data.artlr_type == 'RI'">
                        <div class="wrap_line mb-0">
                        <h3 class="pb-1">Surat Rujukan Internal</h3>
                        <a href="javascript:;" @click="downloadReport('suratRujukanInternal','Surat Rujukan Internal')" class="btn btn-success btn-labeled btn-labeled-left">
                            <b><i class="icon-download"></i></b>
                            <span>Unduh</span>
                        </a>
                        </div>
                    </div>
                    <div class="col-md-4" v-if="row.kajianDokterData.data.artlr_type == 'RK'">
                        <div class="wrap_line mb-0">
                        <h3 class="pb-1">Surat Rujukan Kasus</h3>
                        <a href="javascript:;" @click="downloadReport('suratRujukanExternal','Surat Rujukan External')" class="btn btn-success btn-labeled btn-labeled-left">
                            <b><i class="icon-download"></i></b>
                            <span>Unduh</span>
                        </a>
                        </div>
                    </div>
                </template>
            </div>
        </b-modal>

        
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import FormKajianAwal from './FormKajianAwal.vue'
import FormKajianLanjutan from './FormKajianLanjutan.vue'
import FormKajianDokter from './FormKajianDokter.vue'
import FormKajianDokterTerapi from './FormKajianDokterTerapi.vue'
import FormKajianGizi from './FormKajianGizi.vue'
import FormKajianFisio from './FormKajianFisio.vue'
import FormKajianFisioLanjutan from './FormKajianFisioLanjutan.vue'
import FormKajianRadiologi from './FormKajianRadiologi.vue'
import FormKajianLab from './FormKajianLab.vue'


import DetailKajianAwal from './DetailKajianAwal.vue'
import DetailKajianLanjutan from './DetailKajianLanjutan.vue'
import RiwayatRM from './RiwayatRM.vue'

import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  extends: GlobalVue,
  components:{FormKajianAwal,FormKajianLanjutan,DetailKajianAwal,DetailKajianLanjutan,FormKajianDokter,FormKajianDokterTerapi,FormKajianGizi,RiwayatRM,FormKajianFisioLanjutan,FormKajianFisio,FormKajianRadiologi,FormKajianLab},
  data() {
    return {
        isBtnType : '',
        isShowBtn : false,
        isShowBtnLanjutan : false,
        rowReg: {},
        mPekerjaan: [],
        mAlatBantu: [],
        mSkrininGizi: [],
        mSkrininGiziV2: [],
        diagnosa: [],
        mSDKI: [],
        mSLKI: [],
        mSIKI: [],
        mPoli: [],
        mDokter: [],
        mKesadaran: [],
        mICD9: [],
        mICD10: [],
        mRadiologi: [],
        mLab: [],
        pageNow: 1,
        last_page: 1,
        resepDokter: [],
        mObat: [],
        mAlergi: [],
        mObatPreferensi: [],
        mTypeSatuan: [],
        mDosis: [],
        openDetailKajian: false,
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwayatRMFarmasi: [],
        dataRiwayatRMFollowUp: {},

        dataRiwyatRMPerawatIS: '',

        statusElektroterapi: [],
        statusMekanoTerapi: [],
        statusLatihan: [],
        mAlatKesehatan: [],
        mRacikan: [],

        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],
        
        riwayatKeluhan: [],
        riwayatDiagnosa: [],
        riwayatObat: [],
        dataDokter: {},

        // new
        dataICD9: [],
        dataICD10: [],
        riwayatICD9: [],
        riwayatICD10: [],

        mGiziTindakan: [],
        mGiziDiagnosa: [],
        dataGiziTindakan: [],
        dataGiziDiagnosa: [],
        riwayatGiziTindakan: [],
        riwayatGiziDiagnosa: [],
        openReport: false,
        
        dataICD9RM: [],
        dataICD10RM: [],
        mFaskes: [],
        mDokterRujukan: [],


    }
  },
  computed: {
    isList(){
        return !this.$route.params.rmNo
    },
    typeKajian(){
        return this.$route.params.typeKajian
    },
    passToSub(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mPekerjaan: this.mPekerjaan,
        mAlatBantu: this.mAlatBantu,
        mSkrininGizi: this.mSkrininGizi,
        mSkrininGiziV2: this.mSkrininGiziV2,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSLKI: this.mSLKI,
        mSIKI: this.mSIKI,
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat,
        mAlergi: this.mAlergi,
      })
    },
    passToSubLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        diagnosa: this.diagnosa,
        mSDKI: this.mSDKI,
        mSIKI: this.mSIKI,
        mKesadaran: this.mKesadaran,
        historyKajianPerawat: this.historyKajianPerawat,
        mAlergi: this.mAlergi,
      })
    },
    passToSubDokter(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mKesadaran: this.mKesadaran,
        diagnosa: this.diagnosa,
        mICD9: this.mICD9,
        mICD10: this.mICD10,
        mRadiologi: this.mRadiologi,
        mLab: this.mLab,
        riwayatObat: this.riwayatObat,
        riwayatDiagnosa: this.riwayatDiagnosa,
        riwayatKeluhan: this.riwayatKeluhan,

        dataICD9: this.dataICD9,
        dataICD10: this.dataICD10,
        riwayatICD9: this.riwayatICD9,
        riwayatICD10: this.riwayatICD10,

        resepDokter: this.resepDokter,
        mObatPreferensi: this.mObatPreferensi,
        mAlergi: this.mAlergi,
        mTypeSatuan: this.mTypeSatuan,
        
        mObat: this.mObat,
        mDosis: this.mDosis,
        mAlatKesehatan: this.mAlatKesehatan,
        mRacikan: this.mRacikan,
        
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        mFaskes: this.mFaskes,
        mDokterRujukan: this.mDokterRujukan,

        
        dataICD9RM: this.dataICD9RM,
        dataICD10RM: this.dataICD10RM,
      })
    },
    passToSubDokterTerapi(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        
        mICD9: this.mICD9,
        mICD10: this.mICD10,
        dataICD9: this.dataICD9,
        dataICD10: this.dataICD10,
        riwayatICD9: this.riwayatICD9,
        riwayatICD10: this.riwayatICD10,

        statusElektroterapi: this.statusElektroterapi,
        statusMekanoTerapi: this.statusMekanoTerapi,
        statusLatihan: this.statusLatihan,
        
        mPoli: this.mPoli,
        mDokter: this.mDokter,
        
        mFaskes: this.mFaskes,
        mDokterRujukan: this.mDokterRujukan,
        
        dataICD9RM: this.dataICD9RM,
        dataICD10RM: this.dataICD10RM,
      })
    },
    passToSubGizi(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mGiziTindakan: this.mGiziTindakan,
        mGiziDiagnosa: this.mGiziDiagnosa,
        dataGiziTindakan: this.dataGiziTindakan,
        dataGiziDiagnosa: this.dataGiziDiagnosa,
        riwayatGiziTindakan: this.riwayatGiziTindakan,
        riwayatGiziDiagnosa: this.riwayatGiziDiagnosa,
      })
    },
    passToSubPPAFisio(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mKesadaran: this.mKesadaran,
        mAlatBantu: this.mAlatBantu,
      })
    },
    passToSubPPAFisioLanjutan(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        mKesadaran: this.mKesadaran,
      })
    },
    passToSubRadiologi(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataDokter: this.dataDokter
      })
    },
    passToSubLab(){
      return _.assign({
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
        row: this.row,
        rowReg: this.rowReg,
        dataDokter: this.dataDokter
      })
    },
    passToSubRM(){
      return _.assign({
        dataRiwayatRMDokter: this.dataRiwayatRMDokter,
        dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
        dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
        dataRiwayatRMFisio: this.dataRiwayatRMFisio,
        dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
        dataRiwayatRMLab: this.dataRiwayatRMLab,
        dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,
        row: this.row,
        rowReg: this.rowReg,
        dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          
        historyKajianPerawat: this.historyKajianPerawat,
        historyKajianDokter: this.historyKajianDokter,
        historyKajianDokterTerapi: this.historyKajianDokterTerapi,
        historyKajianDokterGizi: this.historyKajianDokterGizi,
        historyKajianFisio: this.historyKajianFisio,
        historyKajianRadiologi: this.historyKajianRadiologi,
        historyKajianLab: this.historyKajianLab,
      })
    }
  },
  mounted(){
    setTimeout(()=>{
        this.initSticky()
    },1000)
    this.apiGet()
  },
  methods: {
    back(){
        this.$router.push({
            name:'Dashboard'
        })
    },
    deleteRiwayatRM(v){
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'delete-data-rm', arm_id: v.arm_id}}
        ).then(res=>{
            this.loadingOverlay = false
            this.$swal({
                title: `Data Berhasil DiHapus`,
                icon: 'success',
            })
            this.apiGet()
        })
    },
    initSticky(){
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if(PageHeaderSticky){    
            const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
            if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                PageHeaderToggle.addEventListener('click', function(e){
                // e.preventDefault()
                PageHeaderSticky.classList.toggle('page-header-sticky-open')
                if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                    PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                }else{
                    PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                }
                })    
            } 
        }
    },

    doOpenRiwayatDokter(v){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-history-kajian-dokter', regId: v}}
        ).then(res=>{
            let resp = res.data
            this.doOpenRiwayatRM(resp.data)
        })
    },

    doOpenRiwayatRM(v){
        this.loadingOverlay = true
        if(v.kajianDokter){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMDokter = res.data
                this.dataRiwayatRMDokter.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMDokter = {}
        }

        if(v.kajianDokterTerapi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianDokterTerapi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMDokterTerapi = res.data
                this.dataRiwayatRMDokterTerapi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMDokterTerapi = {}
        }

        if(v.kajianGizi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianGizi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMDokterGizi = res.data
                this.dataRiwayatRMDokterGizi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMDokterGizi = {}
        }

        if(v.kajianFisio){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianFisio+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMFisio = res.data
                this.dataRiwayatRMFisio.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMFisio = {}
        }

        if(v.kajianRadiologi){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMRadiologi = res.data
                this.dataRiwayatRMRadiologi.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMRadiologi = {}
        }

        if(v.kajianLab){
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.arm_ar_id, 
            ).then(res=>{
                this.loadingOverlay = false
                this.dataRiwayatRMLab = res.data
                this.dataRiwayatRMLab.Config = this.Config
                this.openRwiayatRM = true
            })
        }else{
            this.dataRiwayatRMLab = {}
        }

        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-obat-farmasi', regId: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data
            this.dataRiwayatRMFarmasi = resp.data
            this.loadingOverlay = false
        })

        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'get-riwayat-rekam-medis', ar_id: v.arm_ar_id}}
        ).then(res=>{
            let resp = res.data

            this.historyKajianPerawat = resp.historyKajianPerawat
            this.historyKajianDokter = resp.historyKajianDokter
            this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
            this.historyKajianDokterGizi = resp.historyKajianDokterGizi
            this.historyKajianFisio = resp.historyKajianFisio
            this.historyKajianRadiologi = resp.historyKajianRadiologi
            this.historyKajianLab = resp.historyKajianLab

            this.loadingOverlay = false
        })

        
        Gen.apiRest(
            "/get/"+'FollowUp'+'/'+this.row.ap_id+'?regId='+v.arm_ar_id, 
        ).then(res=>{
            this.loadingOverlay = false
            this.dataRiwayatRMFollowUp = res.data
            this.dataRiwayatRMFollowUp.Config = this.Config
            this.openRwiayatRM = true
        })



        setTimeout(()=>{
            this.loadingOverlay = false
            this.openRwiayatRM = true
        },2000)
    },

    viewKajianPerawat(id=null){
        this.loadingOverlay = true
        if(!id){
            id = this.row.kajianPerawat
        }

        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+id+'?regId='+this.$route.query.regId, 
        ).then(res=>{
            this.loadingOverlay = false
            this.dataRiwyatRMPerawat = res.data
            this.dataRiwyatRMPerawat.Config = this.Config
            this.dataRiwyatRMPerawat.row['isDok'] = true
            this.dataRiwyatRMPerawatIS = res.data.row.arm_is_kajian_awal
            this.openDetailKajianPerawat = true
        })
    },

    toDetail(v,ar_id){
        this.loadingOverlay = true
        Gen.apiRest(
            "/get/"+'RekamMedis'+'/'+this.row.ap_id+'/'+v.kajianPerawatId+'?regId='+ar_id, 
        ).then(res=>{
            this.loadingOverlay = false
            this.openDetailKajian = true
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },
    doRefreshData(){
        this.apiGet()
        window.scrollTo(0,0)
    },
    otherConditional(v){
        return v.arm_created_by == this.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date)
    },
    to24Hours(date){
        return moment(date).add(48, 'hours').format('YYYY-MM-DD')
    },
    apiGet(params = {}, page = 1){
        
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            this.pageNow = page
            this.last_page = this.data.last_page
            
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.params.rmNo){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
    loadMore(){
        this.pageNow = this.pageNow + 1
        this.loadingOverlay = true

        let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug

        Gen.apiRest(
            "/get/"+url, 
            {
            params: {page : this.pageNow}
            }
        ).then(res=>{
            this.loadingOverlay = false
            let newData = res.data.data.data
            for(let i = 0; i < (newData||[]).length; i++){
                this.data.data.push(newData[i])        
            }
            if(!this.isFound){
                this.$router.push({name : this.$route.name}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })
    },
    toKajianAwal(id='add'){
        this.loadingOverlay = true
        
        if(this.rowReg.ar_status == this.sPerawat){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-awal',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'awal' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
    
                this.$socket.emit('refresh_data', {to : this.uPerawat})

            })
        }else if(this.rowReg.ar_status == this.sDokter){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'Y'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokter})
            })
        }else if(this.rowReg.ar_status == this.sFisio){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter-terapi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'Y'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter-terapi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokterFisio})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 9) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 9)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-fisio-awal',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'Y'}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-awal-fisio' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uFisio})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 13) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 13)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-radiologi',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'Y'}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-radiologi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
           
                this.$socket.emit('refresh_data', {to : this.uRadiologi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 12) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 12)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-lab',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'Y'}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-lab' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
           
                this.$socket.emit('refresh_data', {to : this.uLab})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 6) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 6)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-gizi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'Y'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-gizi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uDokterGizi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }

    },
    toKajianLanjut(aka_id,arm_id){
        this.loadingOverlay = true
        if(this.rowReg.ar_status == this.sPerawat){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-lanjutan',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    aka_id: aka_id,
                    refer_id: arm_id
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'lanjutan' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uPerawat})
            })
        }else if(this.rowReg.ar_status == this.sDokter){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'N',
                    refer_id: arm_id
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokter})
            })
        }else if(this.rowReg.ar_status == this.sFisio){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-dokter-terapi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'N',
                    refer_id: arm_id
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-dokter-terapi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})

                this.$socket.emit('refresh_data', {to : this.uDokterFisio})
            })           
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 9) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 9)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-fisio-lanjutan',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'N',refer_id: arm_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-lanjutan-fisio' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uFisio})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 13) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 13)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-radiologi',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'N',refer_id: arm_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-radiologi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
            
                this.$socket.emit('refresh_data', {to : this.uRadiologi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 12) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 12)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'init-kajian-lab',idPasien: this.$route.params.pageSlug, regId: this.$route.query.regId, dokter_id: this.rowReg.ar_dokter_id, poli_id: this.rowReg.ar_mpo_id, is_awal: 'N',refer_id: arm_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false      
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian:'kajian-lab' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uLab})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }else if((this.rowReg.ar_status == this.sPPA && this.user.levelId == 6) || (this.rowReg.ar_status == this.sPPA && this.$route.query.byPassLevel == 6)){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{
                    type:'init-kajian-gizi',
                    idPasien: this.$route.params.pageSlug, 
                    regId: this.$route.query.regId, 
                    dokter_id: this.rowReg.ar_dokter_id, 
                    poli_id: this.rowReg.ar_mpo_id,
                    is_awal: 'N'
                }}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false        
                this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: res.data.arm_id,
                typeKajian: 'kajian-gizi' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
                
                this.$socket.emit('refresh_data', {to : this.uDokterGizi})
                this.$socket.emit('refresh_data', {to : 'ALLPJ'})
            })
        }
    },
    redirect(v){
        if(v.arm_status == this.sPerawat){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sDokter){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-dokter'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sFisio){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-dokter-terapi'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sPPA && this.user.levelId == 6){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-gizi'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sPPA && this.user.levelId == 9){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: v.arm_is_kajian_awal == 'Y' ? 'kajian-awal-fisio' : 'kajian-lanjutan-fisio'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sPPA && this.user.levelId == 13){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-radiologi'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }else if(v.arm_status == this.sPPA && this.user.levelId == 12){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian: 'kajian-lab'}, query: {regId: v.arm_ar_id} }).catch(()=>{})
        }
    },
    downloadReport(link,name){
        let data = {
            exptype: 'pdf', type: "export", pasienId: this.$route.params.pageSlug, regId: this.$route.query.regId
        }
        let self = this

        $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${link}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `${name}-${moment().format("YYYY-MM-DD")}.pdf`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
        })
    },
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>